import { useEffect } from 'react'
import { useToggle } from 'ahooks'

export const useIsMounted = () => {
  const [isMounted, { setLeft, setRight }] = useToggle()

  useEffect(() => {
    setRight()

    return setLeft
  }, [setLeft, setRight])

  return isMounted
}

export default useIsMounted
