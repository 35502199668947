import { useController, ControllerRenderProps } from 'react-hook-form'

import { propsWithClassNames } from '@genie-fintech/ui/functions'
import BaseField, {
  BaseTextProps as BaseFieldProps
} from '@genie-fintech/ui/components/fields/BaseText'
import { resolveErrorMessage } from '@genie-fintech/ui/components/hook-fields/common/functions'
import { Icon } from '@genie-fintech/ui/icons'

import { DualHookField } from '$hook-fields/types'
import { CallingCodes } from '$constants/country-data'
import { hideInputAppearance } from '$styles/atoms.css'

import { select, selectContainer, selectIcon } from './styles.css'

type InputProps = Omit<Required<BaseFieldProps>['inputProps'], 'type' | 'min'>
type InheritedProps = Omit<BaseFieldProps, 'inputProps' | 'inputRef' | 'affix'>

export type PhoneProps = InheritedProps & {
  callingCodes?: CallingCodes
  inputProps?: Omit<InputProps, keyof ControllerRenderProps>
}

export const Phone: DualHookField<PhoneProps> = ({
  name,
  name2,
  control,
  disabled,
  callingCodes = [],
  controllerOptions,
  ...fieldProps
}) => {
  const one = useController({
    name,
    control,
    disabled,
    ...controllerOptions
  })
  const two = useController({
    name: name2,
    control,
    disabled,
    ...controllerOptions
  })

  const { value = '', ref: inputRef, ...controlledFieldProps } = one.field

  const errorMessage = `${[
    resolveErrorMessage(one.fieldState.error),
    resolveErrorMessage(two.fieldState.error)
  ].filter(Boolean)}`
  const hasError = !!errorMessage

  const baseFieldProps: BaseFieldProps = {
    disabled,
    error: hasError,
    message: errorMessage,
    ...fieldProps,
    inputRef,
    inputProps: {
      type: 'number',
      min: '0',
      ...propsWithClassNames(fieldProps.inputProps, hideInputAppearance),
      ...controlledFieldProps,
      value
    }
  }

  return (
    <BaseField
      affix={{
        pre: (
          <label onClick={e => e.stopPropagation()} className={selectContainer}>
            <select {...two.field} className={select}>
              {callingCodes.map(({ id, code, name }) => (
                <option key={id} title={name} value={code}>
                  +{code}
                </option>
              ))}
            </select>
            <Icon namespace="UpDown" className={selectIcon} />
          </label>
        )
      }}
      {...baseFieldProps}
    />
  )
}

export default Phone
