import { profile } from '$store/profile'
import { useEffect, useState } from 'react'
import useRouter from './useRouter'

import useSignal from './useSignal'

type Action = VoidFunction

/**
 * Redirect to password replace page, if current active profile needs to.
 */
export const useActiveProfilePasswordReplaceRedirect = () => {
  const { redirectWithQueryParams } = useRouter()

  const [lastAction, setAction] = useState<Action>()

  const profileData = useSignal(profile)

  const id = profileData?.id
  const isProfileLoading = !!profileData?.loading
  const isNeeded = !!profileData?.data?.should_replace_password

  const registerAction = (action: Action = () => undefined) => {
    setAction(() => action)
  }
  const clearAction = () => setAction(undefined)

  // Wait for profile fetching and decides based on it.
  useEffect(() => {
    if (!lastAction || !id || isProfileLoading) return

    if (isNeeded) {
      redirectWithQueryParams('authReplacePassword')
      clearAction()

      return
    }

    lastAction()
    clearAction()
  }, [id, isNeeded, isProfileLoading, lastAction, redirectWithQueryParams])

  return registerAction
}

export default useActiveProfilePasswordReplaceRedirect
