import useCacheQueryParamsOnce from './useCacheQueryParmsOnce'

import usePrepareApi from './usePrepareApi'
import useAutoRemoveInactiveSession from './useAutoRemoveInactiveSession'

export const useBootstrap = () => {
  useCacheQueryParamsOnce()
  usePrepareApi()
  useAutoRemoveInactiveSession()
}

export default useBootstrap
