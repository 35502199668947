import { mapValues } from 'lodash-es'
import { Schema, Simplify } from 'type-fest'

import { HslString, HslaString } from './type'

const hslaToValues = (hsl: string) => {
  const normalizeHsl = hsl
    .replace('hsl(', '')
    .replace('hsla(', '')
    .replace(')', '')

  // for Comma splitter
  if (normalizeHsl.includes(',')) {
    const [h, s, l, a] = normalizeHsl.replaceAll(' ', '').split(',')

    return { h, s, l, a }
  }

  // for Space splitter
  const [h, s, l, a] = normalizeHsl
    .split(' ')
    .map(v => v.replaceAll(' ', ''))
    .filter(Boolean)

  return { h, s, l, a }
}

export const toHslValues = <T extends Record<string, HslString>>(colors: T) => {
  return mapValues(colors, hsl => {
    const { h, s, l } = hslaToValues(hsl)

    return `${h},${s},${l}` as const
  })
}

export const toHslaValues = <T extends Record<string, HslaString>>(
  colors: T
) => {
  return mapValues(colors, hsl => {
    const { h, s, l, a } = hslaToValues(hsl)

    return `${h},${s},${l},${a}` as const
  })
}

export const markedDefaultKey = 'default' as const

/** Adds a `default` property. With the value of selected `name`. */
const markDefault = <T extends object>(name: keyof T, values: T) => ({
  [markedDefaultKey]: values[name],
  ...values
})

/**
 * Type casting utility.
 * Returned schema is casted with {@typedef `HslString`}.
 */
export const castColorType = <T extends object>(values: T) => {
  type Values = Schema<typeof values, HslString>

  return values as Simplify<Values>
}

/**
 * Type casting utility.
 * Returned schema is casted with {@typedef `HslaString`}.
 */
export const castAlphaType = <T extends object>(values: T) => {
  type Values = Schema<typeof values, HslaString>

  return values as Simplify<Values>
}

/**
 * Uses `markDefault` & `castColorType` function.
 */
export const markDefaultColor = <T extends object>(
  name: keyof T,
  values: T
) => {
  return castColorType(markDefault(name, values))
}

/**
 * Uses `markDefault` & `castColorType` function.
 */
export const markDefaultAlpha = <T extends object>(
  name: keyof T,
  values: T
) => {
  return castAlphaType(markDefault(name, values))
}
