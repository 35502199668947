import { useEffect, useState } from 'react'
import { Signal, ReadonlySignal } from '@preact/signals-core'

export const useSignal = <T extends Signal | ReadonlySignal>(signal: T) => {
  const [state, setState] = useState<T['value']>(() => signal.value)

  useEffect(() => {
    const cleanup = signal.subscribe(setState)

    return cleanup
  }, [signal])

  return state
}

export default useSignal
