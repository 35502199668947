import { FC, PropsWithChildren } from 'react'

import { useIsMounted } from '@genie-fintech/ui/hooks'
import { trueOrUndefined } from '@genie-fintech/ui/functions'

import useLayoutClass from '$browser/useLayoutClass'

import { container } from './styles.css'

export const CenteredLayout: FC<PropsWithChildren> = ({ children }) => {
  useLayoutClass({ name: 'CenteredLayout' })

  const ready = useIsMounted()

  return (
    <main className={container} data-ready={trueOrUndefined(ready)}>
      {children}
    </main>
  )
}

export default CenteredLayout
