import { useController, ControllerRenderProps } from 'react-hook-form'

import InputText, {
  BaseTextProps as InputTextProps
} from '../../fields/BaseText'

import { HookField } from '../common/types'
import { resolveErrorMessage } from '../common/functions'

type InputProps = Required<InputTextProps>['inputProps']

export type BaseTextProps = Omit<InputTextProps, 'inputProps' | 'inputRef'> & {
  inputProps?: Omit<InputProps, keyof ControllerRenderProps>
}

export const BaseText: HookField<BaseTextProps> = ({
  name,
  control,
  disabled,
  controllerOptions,
  ...fieldProps
}) => {
  const { field, fieldState } = useController({
    name,
    control,
    disabled,
    ...controllerOptions
  })

  const { value = '', ref: inputRef, ...controlledFieldProps } = field

  const errorMessage = resolveErrorMessage(fieldState.error)
  const hasError = !!errorMessage

  const fieldPropBindings: InputTextProps = {
    disabled,
    error: hasError,
    message: errorMessage,
    ...fieldProps,
    inputRef,
    inputProps: {
      ...fieldProps.inputProps,
      ...controlledFieldProps,
      value
    }
  }

  return <InputText {...fieldPropBindings} />
}

export default BaseText
