import React from 'react'
import ReactDOM from 'react-dom/client'

import {
  init,
  ErrorBoundary,
  withProfiler,
  replayIntegration
} from '@sentry/react'
import { browserTracingIntegration } from '@sentry/browser'

import CenteredLayout from '$layouts/CenteredLayout'

import App from '$app/App'

import 'unfonts.css'
import '@genie-fintech/ui/style/global'
import '@genie-fintech/ui/style/global/attach-system-theme.css'
import '$styles/global'

import '$app/states/initialize'

init({
  enabled: import.meta.env.PROD,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  integrations: [browserTracingIntegration(), replayIntegration()],
  tracesSampleRate: import.meta.env.DEV ? 1 : 0.1,
  replaysSessionSampleRate: import.meta.env.DEV ? 1 : 0.1
})

const TrackedApp = withProfiler(App)

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ErrorBoundary
      showDialog
      fallback={
        <CenteredLayout>
          The application has stopped working. Please get in touch with the
          administrators.
        </CenteredLayout>
      }
    >
      <TrackedApp />
    </ErrorBoundary>
  </React.StrictMode>
)
