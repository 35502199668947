import { useCallback } from 'react'

import lazyToast from '$services/lazyToast'

import { Profile } from '$api/auth'
import { CommonAPI } from '$api/common'

import { removeTokens, TokenDetail } from '$store/session/tokens'

import useTokenLogout from './useTokenLogout'

const { errorMessageResolver } = new CommonAPI()

export const useTokenProfileLogout = (
  token?: TokenDetail['token'],
  profile?: Profile
) => {
  const result = useTokenLogout()

  const { runAsync } = result

  const logout = useCallback(() => {
    if (!profile) return

    lazyToast(runAsync(token), {
      loading: 'Clearing session..',
      error: errorMessageResolver,
      success: () => {
        // Remove token
        removeTokens([profile.id])

        return (
          <span>
            Successfully removed <strong>{profile.email}</strong>
          </span>
        )
      }
    })
  }, [profile, runAsync, token])

  return {
    ...result,
    logout
  }
}

export default useTokenProfileLogout
