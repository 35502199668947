import { FC, PropsWithChildren, ReactNode } from 'react'

import { propsWithClassNames, trueOrUndefined } from '../../functions'
import { Icon, IconProps } from '../../icons'
import { TagsNoRef, ToReferences } from '../../types'

import {
  HeaderVariants,
  leftAside,
  rightAside,
  nav,
  main,
  header,
  closeButton,
  icon as iconStyle,
  closeIcon,
  title as titleStyle,
  content,
  iconPad,
  inner
} from './style.css'

type VNodes = {
  headerRef: HTMLElement
  innerRef: HTMLElement
  leftAsideRef: HTMLElement
  rightAsideRef: HTMLElement
  navRef: HTMLElement
  mainRef: HTMLElement
  contentRef: HTMLElement
  closeButtonRef: HTMLButtonElement
}

type References = Partial<ToReferences<VNodes>>

type ElementProps = Partial<{
  headerProps: TagsNoRef<'header'>
  innerProps?: TagsNoRef<'section'>
  leftAsideProps: TagsNoRef<'aside'>
  rightAsideProps: TagsNoRef<'aside'>
  navProps: TagsNoRef<'nav'>
  mainProps: TagsNoRef<'main'>
  contentProps: TagsNoRef<'article'>
  closeButtonIconProps: Omit<IconProps, 'namespace'>
  iconProps: Omit<IconProps, 'namespace'>
  iconPadProps: TagsNoRef<'figure'>
  closeButtonProps: TagsNoRef<'button'>
}>

type DataProps = {
  title?: ReactNode
  icon?: IconProps['namespace']
  closeButtonIcon?: IconProps['namespace']
  showCloseButton?: boolean
}

export type ToastProps = PropsWithChildren<
  ElementProps & HeaderVariants & References & DataProps
>

export const Toast: FC<ToastProps> = ({
  kind,
  type,
  icon = 'Warning',
  closeButtonIcon = 'Cross',
  title,
  children,
  headerRef,
  leftAsideRef,
  rightAsideRef,
  navRef,
  mainRef,
  contentRef,
  closeButtonRef,
  leftAsideProps,
  rightAsideProps,
  navProps,
  mainProps,
  headerProps,
  contentProps,
  iconProps,
  iconPadProps,
  showCloseButton,
  fullWidth,
  closeButtonProps,
  closeButtonIconProps,
  innerProps,
  innerRef
}) => (
  <header
    ref={headerRef}
    role="status"
    {...propsWithClassNames(headerProps, header({ type, kind, fullWidth }))}
  >
    <section
      ref={innerRef}
      data-show-close-button={trueOrUndefined(showCloseButton)}
      data-no-children={trueOrUndefined(!children)}
      {...propsWithClassNames(innerProps, inner)}
    >
      <aside
        ref={leftAsideRef}
        {...propsWithClassNames(leftAsideProps, leftAside)}
      >
        <figure {...propsWithClassNames(iconPadProps, iconPad)}>
          <Icon
            namespace={icon}
            {...propsWithClassNames(iconProps, iconStyle)}
          />
        </figure>
      </aside>

      <main ref={mainRef} {...propsWithClassNames(mainProps, main)}>
        <nav ref={navRef} {...propsWithClassNames(navProps, nav)}>
          <h3 className={titleStyle}>{title}</h3>
        </nav>

        {children && (
          <article
            ref={contentRef}
            {...propsWithClassNames(contentProps, content)}
          >
            {children}
          </article>
        )}
      </main>

      {showCloseButton && (
        <aside
          ref={rightAsideRef}
          {...propsWithClassNames(rightAsideProps, rightAside)}
        >
          <button
            ref={closeButtonRef}
            type="button"
            {...propsWithClassNames(closeButtonProps, closeButton)}
          >
            <Icon
              namespace={closeButtonIcon}
              {...propsWithClassNames(closeButtonIconProps, closeIcon)}
            />
          </button>
        </aside>
      )}
    </section>
  </header>
)

export default Toast
