import { computed, signal } from '@preact/signals-core'

import { isAllTrue } from '$app/utilities'

import { hasActiveSource } from '$model/api'

import { with_state as tokenStates } from './session/tokens'
import { currentTokenState } from './session'
import { cached } from './queryParams'
import { settings } from './settings'

/**
 * Type: Store
 */
const apiPrepared = signal(false)

/**
 * Type: Readonly
 */
export const status = computed(() => {
  const isApiPrepared = apiPrepared.value
  const hasApiActiveSource = hasActiveSource.value

  const isActiveSessionAlive = !!currentTokenState.value?.alive
  const hasActiveSession = !!currentTokenState.value
  const hasSession = !!tokenStates.value.length

  const isSettingsLoading = settings.value.loading
  const isSettingsReady = settings.value.checked && !!settings.value.data

  const isQueryParamsCached = cached.value

  const tokensChecked = isAllTrue(
    tokenStates.value.map(({ state }) => !!state?.checked)
  )

  const tokensLoading = !!tokenStates.value
    .map(({ state }) => state?.loading)
    .filter(Boolean).length

  return {
    isApiPrepared,
    tokensChecked,
    tokensLoading,
    hasApiActiveSource,
    hasSession,
    hasActiveSession,
    isActiveSessionAlive,
    isQueryParamsCached,
    isSettingsReady,
    isSettingsLoading
  }
})

export const setApiPrepared = (status: boolean) => {
  apiPrepared.value = status
}
