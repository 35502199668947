import { FC } from 'react'
import { BaseText, BaseTextProps } from '..'
import { Icon } from '../../../icons'
import { buttonStyle, iconStyle } from './style.css'
import { useToggle } from 'ahooks'

export type PasswordProps = Omit<BaseTextProps, 'affix'>

export const Password: FC<PasswordProps> = ({
  disabled,
  inputProps,
  ...restBaseTextProps
}) => {
  const [isShow, { toggle }] = useToggle()

  const EyeIcon = (
    <button
      type="button"
      className={buttonStyle}
      disabled={disabled}
      onClick={e => {
        e.stopPropagation()
        toggle()
      }}
    >
      <Icon namespace={isShow ? 'EyeOn' : 'EyeOff'} className={iconStyle} />
    </button>
  )

  return (
    <BaseText
      disabled={disabled}
      affix={{
        post: EyeIcon
      }}
      inputProps={{
        type: isShow ? 'text' : 'password',
        ...inputProps
      }}
      {...restBaseTextProps}
    />
  )
}

export default Password
