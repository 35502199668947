import { FC } from 'react'
import { LiteralUnion } from 'type-fest'

import { StatusVariants, status } from '@genie-fintech/ui/style/element'

import { KnownEnvironments } from '$constants/environments'

import EnvIcon, { EnvIconProps } from '$icons/EnvIcon'
import {
  ContainerVariants,
  container,
  horizontal,
  icon,
  iconPad
} from './styles.css'

type EnvironmentTagProps = {
  env: LiteralUnion<KnownEnvironments, string>
  size?: 'big' | 'small' | 'horizontal'
}

type Info = {
  name: string
  iconName: EnvIconProps['name']
  type: ContainerVariants['type']
  statusKind: StatusVariants['kind']
}

export const EnvironmentTag: FC<EnvironmentTagProps> = ({
  env,
  size = 'horizontal'
}) => {
  const { name, iconName, statusKind, type }: Info = (() => {
    const iconName = env as Info['iconName']
    const type = env as Info['type']

    switch (env) {
      case 'development': {
        return {
          iconName,
          type,
          name: 'dev',
          statusKind: 'secondary'
        }
      }

      case 'production': {
        return {
          iconName,
          type,
          name: 'prd',
          statusKind: 'danger'
        }
      }

      case 'staging': {
        return {
          iconName,
          type,
          name: 'stg',
          statusKind: 'tertiary'
        }
      }

      case 'uat': {
        return {
          iconName,
          type,
          name: 'uat',
          statusKind: 'warning'
        }
      }

      case 'local': {
        return {
          iconName,
          type,
          name: 'uat',
          statusKind: 'primary'
        }
      }

      case 'qa': {
        return {
          iconName,
          type,
          name: 'qa',
          statusKind: 'danger'
        }
      }

      default: {
        return {
          iconName: 'unknown',
          type: 'unknown',
          name: env,
          statusKind: 'grey'
        }
      }
    }
  })()

  return (
    <span className={container({ type })}>
      {size === 'big' && (
        <span className={iconPad}>
          <EnvIcon name={iconName} className={icon} />
        </span>
      )}

      {size === 'small' && <EnvIcon name={iconName} className={icon} />}

      {size === 'horizontal' && (
        <span className={horizontal}>
          <span
            className={status({
              size: 'tiny',
              inline: true,
              type: 'faint',
              kind: statusKind
            })}
          >
            {name}
          </span>

          <EnvIcon name={iconName} className={icon} />
        </span>
      )}
    </span>
  )
}

export default EnvironmentTag
