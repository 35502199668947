import { FC, ReactNode, forwardRef, isValidElement } from 'react'

import { Icon, IconProps, IconPropsReferences } from '../../icons'

import { toClassNames, trueOrUndefined } from '../../functions'
import { TagsNoRef, ToReferences } from '../../types'

import {
  FigureVariants,
  SpanVariants,
  canvas,
  figure,
  img,
  span,
  userIcon,
  status as statusStyle,
  statusAddIcon
} from './style.css'

type VNodes = {
  figureRef: HTMLElement
  spanRef: HTMLSpanElement
  imgRef: HTMLImageElement
  canvasRef: HTMLSpanElement
}

type References = Partial<
  ToReferences<VNodes> & { iconRefs: IconPropsReferences }
>

type ImgProps = TagsNoRef<'img'>
type Variants = FigureVariants & SpanVariants

export type AvatarProps = References & {
  size?: number | string
  image?: ImgProps | ReactNode
  iconNamespace?: IconProps['namespace']
} & Variants

const Img = forwardRef<VNodes['imgRef'], ImgProps>(
  ({ className, ...props }, ref) => (
    <img className={toClassNames(img, className)} ref={ref} {...props} />
  )
)

export const Avatar: FC<AvatarProps> = ({
  image,
  size = 20,
  iconNamespace = 'User',
  doubleBorder,
  status,
  active,
  type,
  imgRef,
  figureRef,
  spanRef,
  canvasRef,
  iconRefs
}) => {
  const hasImage = !!image
  const isNode = isValidElement(image)
  const isImg = hasImage && !isNode

  return (
    <figure
      ref={figureRef}
      className={figure({ doubleBorder, active, status })}
      style={{ fontSize: size }}
    >
      <span ref={spanRef} className={span({ type })}>
        {isNode && image}

        {isImg && <Img ref={imgRef} {...(image as ImgProps)} />}

        <Icon
          className={userIcon}
          namespace={iconNamespace}
          data-hidden={trueOrUndefined(hasImage)}
          {...iconRefs}
        />

        <span ref={canvasRef} className={canvas} />
      </span>

      {status && (
        <span className={statusStyle}>
          {status == 'add' && (
            <Icon namespace="Add" className={statusAddIcon} />
          )}
        </span>
      )}
    </figure>
  )
}

export default Avatar
