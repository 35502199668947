import { FC } from 'react'
import { useRequest } from 'ahooks'

import useReturnTo from '$actions/useReturnTo'

import lazyToast from '$services/lazyToast'
import api from '$model/api'

import ResetPasswordForm, {
  ResetPasswordFormProps
} from '$hook-forms/ResetPasswordForm'

import { container, title } from './styles.css'

const { resetPassword, errorMessageResolver } = api.value.auth

export const ResetPasswordBlock: FC<{
  email: string
  token: string
}> = ({ email, token }) => {
  const redirect = useReturnTo()

  const { loading, runAsync } = useRequest(resetPassword, { manual: true })

  const onSuccess: ResetPasswordFormProps['onSuccess'] = async (
    reset,
    { password }
  ) => {
    await lazyToast(
      runAsync({ email, token, password }),
      {
        loading: 'Reseting..',
        error: errorMessageResolver,
        success: 'Success! Please login using your new password.'
      },
      { duration: 5000 }
    )

    reset()

    redirect('authLogin')
  }

  return (
    <section className={container}>
      <h2 className={title}>Reset your password</h2>

      <ResetPasswordForm onSuccess={onSuccess} disabled={loading} />
    </section>
  )
}

export default ResetPasswordBlock
