import { FC, useMemo } from 'react'

import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { Password } from '@genie-fintech/ui/components/hook-fields'

import PasswordGuide from '$elements/PasswordGuide'

import { TypeSubmitForm } from '$hook-forms/types'
import useRequiredPasswordString from '$hook-forms/hooks/useRequiredPasswordString'

import { submit } from '$forms/common/style.css'

import { TFields, baseSchema, refineSchema, defaultValues } from './schema'
import { container } from './styles.css'

export type ResetPasswordFormFields = TFields

export type ResetPasswordFormProps = TypeSubmitForm<TFields>

export const ResetPasswordForm: FC<ResetPasswordFormProps> = ({
  onSuccess,
  disabled
}) => {
  const { fieldSchema, getFailedRules } = useRequiredPasswordString()

  const dynamicSchema = useMemo(
    () => refineSchema(baseSchema.extend({ password: fieldSchema })),
    [fieldSchema]
  )
  const resolver = zodResolver(dynamicSchema)

  const { control, reset, handleSubmit, formState, getValues } = useForm({
    resolver,
    defaultValues,
    mode: 'all'
  })

  const isInvalid = disabled || !!Object.keys(formState.errors).length

  const passwordValue = getValues('password')
  const hasPassword = !!passwordValue
  const invalidRules = useMemo(
    () => getFailedRules(passwordValue),
    [passwordValue, getFailedRules]
  )

  return (
    <form
      className={container}
      onSubmit={handleSubmit((...handler) => onSuccess(reset, ...handler))}
      noValidate
      autoComplete="off"
    >
      <Password
        control={control}
        name="password"
        label="New Password"
        disabled={disabled}
      />
      <Password
        control={control}
        name="confirm_password"
        label="Confirm password"
        disabled={disabled}
      />

      <button type="submit" className={submit} disabled={isInvalid}>
        Reset
      </button>

      <PasswordGuide invalidRules={invalidRules} disabled={!hasPassword} />
    </form>
  )
}

export default ResetPasswordForm
