import { FC } from 'react'

import { DEFAULT_ROUTE } from '$constants'

import useReturnTo from '$actions/useReturnTo'
import useRouter from '$actions/useRouter'
import usePageClass from '$browser/usePageClass'

import AccountSelection from '$blocks/AccountSelection'

import { updateCurrent } from '$store/session'

import useAutoRequestLogin from '$actions/useAutoRequestLogin'
import useActiveProfilePasswordReplaceRedirect from '$actions/useActiveProfilePasswordReplaceRedirect'

const pageName = 'Accounts'

/**
 * Authorized `session` listing page.
 *
 * After selecting one session, page will redirect
 * to `?return_to=...` or fallback to `DEFAULT_ROUTE` route.
 *
 * On mount: This page will:
 * - Redirect to login when unauthorized. Otherwise displays a list of authenticated account.
 *
 * On session selection:
 * - Selected session will be marked as `active`.
 *
 * On add new:
 * - Redirects to `authLogin`(Login) page, also carries current page's URL query parameters.
 */
export const Accounts: FC = () => {
  usePageClass({ name: pageName })

  useAutoRequestLogin()

  const { redirectWithQueryParams } = useRouter()
  const returnTo = useReturnTo()
  const replaceRedirect = useActiveProfilePasswordReplaceRedirect()

  return (
    <AccountSelection
      onAddNew={() => {
        redirectWithQueryParams('authLogin')
      }}
      // Redirect when session is selected, favors `return_to` route
      onSelect={id => {
        updateCurrent(id)

        replaceRedirect(() => returnTo(DEFAULT_ROUTE, { replace: true }))
      }}
    />
  )
}

export default Accounts
