import { FC } from 'react'
import { assignInlineVars } from '@vanilla-extract/dynamic'

import { propsWithClassNames } from '../../functions'

import { Icon, IconProps } from '../../icons'
import { iconVars } from '../../icons/Icon/style.css'

import { icon } from './style.css'

export type SpinnerProps = Omit<IconProps, 'namespace'> & {
  size?: string
}

export const Spinner: FC<SpinnerProps> = ({
  size,
  style,
  color = 'primary.default',
  ...props
}) => (
  <Icon
    namespace="Loading"
    color={color}
    style={{
      ...(size &&
        assignInlineVars({
          [iconVars.width]: size,
          [iconVars.height]: size
        })),
      ...style
    }}
    {...propsWithClassNames(props, icon)}
  />
)

export default Spinner
