import { URLPattern } from 'urlpattern-polyfill'
import { CreateHistory } from 'rutter'

import { AuthQueryParams } from '$constants/auth'
import { ProfilePageQueryParams } from '$constants/profile'

export const {
  on,
  onOneOf,
  redirect,
  routeState,
  summaryState,
  watchRouteState,
  watchSummaryState,
  getDetail
} = new CreateHistory({
  URLPattern,
  routes: {
    root: {
      pathname: '/'
    },
    profileDetail: {
      pathname: '/profile',
      meta: {
        title: 'Profile'
      }
    },
    auth: {
      pathname: '/auth',
      ignore: true,
      meta: {
        title: 'Authentication'
      }
    },
    authLogin: {
      pathname: '/auth/login',
      meta: {
        title: 'Login'
      }
    },
    authReplacePassword: {
      pathname: '/auth/replace-password',
      meta: {
        title: 'Replace Password'
      }
    },
    authForgotPassword: {
      pathname: '/auth/forgot-password',
      meta: {
        title: 'Forgot Password'
      }
    },
    authResetPassword: {
      pathname: '/auth/reset-password',
      meta: {
        title: 'Reset Password'
      }
    },
    authSmsOtpVerify: {
      pathname: '/auth/sms-otp/verify',
      meta: {
        title: 'Verify OTP'
      }
    },
    authAccounts: {
      pathname: '/auth/accounts',
      meta: {
        title: 'Accounts'
      }
    },
    authApp: {
      pathname: '/auth/app',
      meta: {
        title: 'Authorize Application'
      }
    }
  }
})

export type RouteNames = Exclude<typeof routeState.name, undefined>

type AnyQueryParams = Partial<Record<string, string>>

export type ExtendedQueryParams = {
  return_to?: RouteNames
} & AuthQueryParams &
  ProfilePageQueryParams &
  AnyQueryParams
