import { FC, PropsWithChildren } from 'react'
import { Trigger as RadixTrigger, TabsTriggerProps } from '@radix-ui/react-tabs'

import { propsWithClassNames } from '../../functions'
import { trigger } from './style.css'

export type TriggerProps = TabsTriggerProps

export const Trigger: FC<PropsWithChildren<TriggerProps>> = props => (
  <RadixTrigger {...propsWithClassNames(props, trigger)} />
)

export default Trigger
