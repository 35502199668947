import { FC, ReactNode } from 'react'

import { TagsNoRef } from '@genie-fintech/ui/types'
import { propsWithClassNames } from '@genie-fintech/ui/functions'

import { copyrightText } from '$constants/texts'

import { container } from './styles.css'

export type CopyrightTextProps = TagsNoRef<'div'> & { text?: ReactNode }

export const CopyrightText: FC<CopyrightTextProps> = ({
  text = <p dangerouslySetInnerHTML={{ __html: copyrightText }} />,
  ...containerProps
}) => <div {...propsWithClassNames(containerProps, container)}>{text}</div>

export default CopyrightText
