import { toast } from 'react-hot-toast'
import { FC, useCallback, useEffect, useState } from 'react'

import { Spinner } from '@genie-fintech/ui/components'

import { DEFAULT_ROUTE } from '$constants'

import { wait, buildURL } from '$app/utilities'

import usePageClass from '$browser/usePageClass'
import useRouter from '$actions/useRouter'
import useSignal from '$actions/useSignal'

import queryParams from '$store/queryParams'
import { status } from '$store/status'

import api from '$model/api'
import { GetCodeRequest } from '$api/auth'

import { content, container, spinner } from './styles.css'

const pageName = 'App'

export const App: FC = () => {
  usePageClass({ name: pageName })

  const { redirect } = useRouter()

  const { auth } = useSignal(api)
  const { hasApiActiveSource } = useSignal(status)
  const { params } = useSignal(queryParams)

  const [message, setMessage] = useState('Connecting your service...')

  const connect = useCallback(async () => {
    try {
      const {
        client_id,
        code_challenge,
        code_challenge_method,
        redirect_uri,
        response_type,
        scope,
        state
      } = params as GetCodeRequest

      const { code } = auth.successResolver(
        await auth.getCode({
          client_id,
          code_challenge,
          code_challenge_method,
          redirect_uri,
          response_type,
          scope,
          state
        })
      ).data

      if (redirect_uri) {
        const URL = buildURL(redirect_uri, {
          queryParams: { code, state }
        })

        setMessage('Redirecting to service..')

        await wait(3000)

        window.location.replace(URL)
      }
    } catch (err) {
      const message = auth.errorMessageResolver(
        err,
        'Failed to reach service. Please contact support.'
      )

      toast.error(message)

      redirect(DEFAULT_ROUTE)
    }
  }, [params, redirect, auth])

  useEffect(() => {
    if (!hasApiActiveSource) return

    connect()
  }, [hasApiActiveSource, connect])

  return (
    <article className={container}>
      <Spinner className={spinner} />

      <main className={content}>{message}</main>
    </article>
  )
}

export default App
