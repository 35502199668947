import { format } from 'date-fns'

import { getThemedData } from '$app/utilities'

export const serviceName = getThemedData({
  carro: 'Carro SSO',
  genie: 'Genie Auth'
})

export const authLayoutDescriptionText = (() => {
  const t = (text1: string, text2: string) => {
    return `To access the application from ${text1}, please log in with ${text2} service.`
  }

  return getThemedData({
    carro: t('CARRO', serviceName),
    genie: t('GENIE', serviceName)
  })
})()

export const copyrightText = (() => {
  const year = format(new Date(), 'yyyy')

  return `&copy;${year} ${serviceName} Service. All rights reserved.`
})()

export const loginFormPlaceholderText = (() => {
  const email = (text: string) => `E.g. user@${text}`

  return {
    email: getThemedData({
      carro: email('carro.co'),
      genie: email('geniefintech.com')
    })
  }
})()
