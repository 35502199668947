import { FC, ReactNode } from 'react'

import { Avatar, AvatarProps } from '@genie-fintech/ui/components'
import { TagsNoRef } from '@genie-fintech/ui/types'
import { propsWithClassNames } from '@genie-fintech/ui/functions'

import {
  container,
  info,
  alias as aliasStyle,
  name as nameStyle
} from './styles.css'

type ElementProps = {
  containerProps?: TagsNoRef<'span'>
  infoProps?: TagsNoRef<'span'>
  nameProps?: TagsNoRef<'span'>
  aliasProps?: TagsNoRef<'span'>
}

export type ProfileInfoProps = ElementProps & {
  name?: string
  alias?: string
  photo?: Exclude<Required<AvatarProps['image']>, ReactNode>['src']
}

export const ProfileInfo: FC<ProfileInfoProps> = ({
  photo,
  alias,
  name,
  aliasProps,
  containerProps,
  infoProps,
  nameProps
}) => (
  <span {...propsWithClassNames(containerProps, container)}>
    <Avatar status="online" size={32} image={{ src: photo }} />

    {(name || alias) && (
      <span {...propsWithClassNames(infoProps, info)}>
        {name && (
          <span {...propsWithClassNames(nameProps, nameStyle)}>{name}</span>
        )}
        {alias && (
          <span {...propsWithClassNames(aliasProps, aliasStyle)}>{alias}</span>
        )}
      </span>
    )}
  </span>
)

export default ProfileInfo
