import { FC } from 'react'
import { useRequest } from 'ahooks'

import useRouter from '$actions/useRouter'

import Link from '$elements/Link'

import lazyToast from '$services/lazyToast'
import api from '$model/api'

import ForgotPasswordForm, {
  ForgotPasswordFormProps
} from '$hook-forms/ForgotPasswordForm'

import { container, loginLink, title } from './styles.css'

const { forgotPassword, errorMessageResolver } = api.value.auth

export const ForgotPassword: FC = () => {
  const { redirectWithQueryParams } = useRouter()

  const { loading, runAsync } = useRequest(forgotPassword, { manual: true })

  const onSuccess: ForgotPasswordFormProps['onSuccess'] = async (
    reset,
    data
  ) => {
    await lazyToast(
      runAsync({ ...data, request_origin: self.origin }),
      {
        loading: 'Requesting..',
        error: errorMessageResolver,
        success: 'Success! Please check your email for reset link.'
      },
      { duration: 5000 }
    )

    reset()

    // Retain ?return_to query
    redirectWithQueryParams('authLogin')
  }

  return (
    <section className={container}>
      <h2 className={title}>Get password reset link</h2>

      <ForgotPasswordForm onSuccess={onSuccess} disabled={loading} />

      <Link
        to="authLogin"
        // Retain ?return_to query
        options={({ queryParams }) => ({ queryParams })}
        className={loginLink}
      >
        log into your account
      </Link>
    </section>
  )
}

export default ForgotPassword
