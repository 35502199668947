import { FC } from 'react'

import usePageClass from '$browser/usePageClass'

import SmsOtpVerifyBlock from '$blocks/SmsOtpVerifyBlock'

const pageName = 'SmsOtpVerify'

export const SmsOtpVerify: FC = () => {
  usePageClass({ name: pageName })

  return <SmsOtpVerifyBlock />
}

export default SmsOtpVerify
