import {
  toHslValues,
  markDefaultColor,
  castColorType,
  castAlphaType,
  toHslaValues
} from './functions'

const alphaPrimaryColors = {
  '0': '',
  '1': '',
  '2': '',
  '3': '',
  '4': '',
  '5': ''
} as const

const alphaSecondaryColors = {
  '0': '',
  '1': '',
  '2': '',
  '3': '',
  '4': '',
  '5': ''
} as const

const alphaTertiaryColors = {
  '0': '',
  '1': '',
  '2': '',
  '3': '',
  '4': '',
  '5': ''
} as const

const alphaNeutralColors = {
  '0': '',
  '1': '',
  '2': '',
  '3': '',
  '4': '',
  '5': ''
} as const

const alphaSuccessColors = {
  '0': '',
  '1': '',
  '2': '',
  '3': '',
  '4': '',
  '5': ''
} as const

const alphaWarningColors = {
  '0': '',
  '1': '',
  '2': '',
  '3': '',
  '4': '',
  '5': ''
} as const

const alphaDangerColors = {
  '0': '',
  '1': '',
  '2': '',
  '3': '',
  '4': '',
  '5': ''
} as const

const alphaAreaColors = {
  disabled: ''
} as const

const primaryColors = {
  '0': '',
  '10': '',
  '20': '',
  '30': '',
  '40': '',
  '50': '',
  '60': '',
  '70': '',
  '80': '',
  '90': '',
  '100': ''
} as const

const secondaryColors = {
  '0': '',
  '10': '',
  '20': '',
  '30': '',
  '40': '',
  '50': '',
  '60': '',
  '70': '',
  '80': '',
  '90': '',
  '100': ''
} as const

const tertiaryColors = {
  '0': '',
  '10': '',
  '20': '',
  '30': '',
  '40': '',
  '50': '',
  '60': '',
  '70': '',
  '80': '',
  '90': '',
  '100': ''
} as const

const neutralColors = {
  '0': '',
  '10': '',
  '20': '',
  '30': '',
  '40': '',
  '50': '',
  '60': '',
  '70': '',
  '80': '',
  '90': '',
  '100': ''
} as const

const successColors = {
  '0': '',
  '10': '',
  '20': '',
  '30': '',
  '40': '',
  '50': '',
  '60': '',
  '70': '',
  '80': '',
  '90': '',
  '100': ''
} as const

const warningColors = {
  '0': '',
  '10': '',
  '20': '',
  '30': '',
  '40': '',
  '50': '',
  '60': '',
  '70': '',
  '80': '',
  '90': '',
  '100': ''
} as const

const dangerColors = {
  '0': '',
  '10': '',
  '20': '',
  '30': '',
  '40': '',
  '50': '',
  '60': '',
  '70': '',
  '80': '',
  '90': '',
  '100': ''
} as const

const infoColors = {
  '0': '',
  '10': '',
  '20': '',
  '30': '',
  '40': '',
  '50': '',
  '60': '',
  '70': '',
  '80': '',
  '90': '',
  '100': ''
} as const

const areaColors = {
  high: '',
  low: '',
  medium: '',
  disabled: ''
} as const

const textColors = {
  neutral: '',
  onBackground: '',
  light: '',
  disabled: ''
} as const

const absoluteColors = {
  light: 'hsl(0 0% 100%)',
  dark: 'hsl(229, 22%, 10%)'
} as const

const alphaPrimary = castAlphaType(alphaPrimaryColors)
const alphaSecondary = castAlphaType(alphaSecondaryColors)
const alphaTertiary = castAlphaType(alphaTertiaryColors)
const alphaNeutral = castAlphaType(alphaNeutralColors)
const alphaSuccess = castAlphaType(alphaSuccessColors)
const alphaWarning = castAlphaType(alphaWarningColors)
const alphaDanger = castAlphaType(alphaDangerColors)
const alphaArea = castAlphaType(alphaAreaColors)

const alphaPrimaryHsla = toHslaValues(alphaPrimary)
const alphaSecondaryHsla = toHslaValues(alphaSecondary)
const alphaTertiaryHsla = toHslaValues(alphaTertiary)
const alphaNeutralHsla = toHslaValues(alphaNeutral)
const alphaSuccessHsla = toHslaValues(alphaSuccess)
const alphaWarningHsla = toHslaValues(alphaWarning)
const alphaDangerHsla = toHslaValues(alphaDanger)
const alphaAreaHsla = toHslaValues(alphaArea)

const primary = markDefaultColor('70', primaryColors)
const secondary = markDefaultColor('70', secondaryColors)
const tertiary = markDefaultColor('70', tertiaryColors)
const neutral = markDefaultColor('90', neutralColors)
const success = markDefaultColor('30', successColors)
const warning = markDefaultColor('30', warningColors)
const danger = markDefaultColor('30', dangerColors)
const info = markDefaultColor('30', infoColors)
const area = castColorType(areaColors)
const text = markDefaultColor('onBackground', textColors)
const absolute = absoluteColors

const primaryHsl = toHslValues(primary)
const secondaryHsl = toHslValues(secondary)
const tertiaryHsl = toHslValues(tertiary)
const neutralHsl = toHslValues(neutral)
const successHsl = toHslValues(success)
const warningHsl = toHslValues(warning)
const dangerHsl = toHslValues(danger)
const infoHsl = toHslValues(info)
const areaHsl = toHslValues(area)
const textHsl = toHslValues(text)
const absoluteHsl = toHslValues(absolute)

/** Specification variables / Type-info variables */
export const colors = {
  alphaPrimary,
  alphaSecondary,
  alphaTertiary,
  alphaNeutral,
  alphaSuccess,
  alphaWarning,
  alphaDanger,
  alphaArea,

  alphaPrimaryHsla,
  alphaSecondaryHsla,
  alphaTertiaryHsla,
  alphaNeutralHsla,
  alphaSuccessHsla,
  alphaWarningHsla,
  alphaDangerHsla,
  alphaAreaHsla,

  primary,
  secondary,
  tertiary,
  neutral,
  success,
  warning,
  danger,
  info,
  area,

  primaryHsl,
  secondaryHsl,
  tertiaryHsl,
  neutralHsl,
  successHsl,
  warningHsl,
  dangerHsl,
  infoHsl,
  areaHsl,

  text,
  textHsl,

  absolute,
  absoluteHsl
}
