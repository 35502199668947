import { useRequest } from 'ahooks'

import lazyToast from '$services/lazyToast'
import api from '$model/api'
import { addToken } from '$store/session'

import { LoginFormProps } from '$hook-forms/LoginForm'

import { useSuccessLogin } from './useSuccessLogin'

const { login, errorMessageResolver, successResolver } = api.value.auth

type OnSuccess = LoginFormProps['onSuccess']

export const useEmailLogin = (): LoginFormProps => {
  const onLoginSuccess = useSuccessLogin()

  const { loading, runAsync } = useRequest(login, { manual: true })

  const onSuccess: OnSuccess = async (reset, data) => {
    const {
      data: { auth_token }
    } = await lazyToast(runAsync(data), {
      loading: 'Verifying..',
      error: e => errorMessageResolver(e, 'Invalid credential'),
      success: 'Successfully logged in'
    }).then(successResolver)

    reset()

    const id = addToken(auth_token)

    onLoginSuccess(id)
  }

  return { onSuccess, disabled: loading }
}

export default useEmailLogin
