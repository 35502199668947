import { useEffect } from 'react'

import { inactive_states, removeTokens } from '$store/session/tokens'
import useSignal from './useSignal'

export const useAutoRemoveInactiveSession = () => {
  const states = useSignal(inactive_states)

  useEffect(() => {
    const ids = states.map(({ id }) => id)

    if (ids.length) {
      removeTokens(ids)
    }
  }, [states])
}

export default useAutoRemoveInactiveSession
