import { FC } from 'react'

import { Namespaces } from '../types'
import { propsWithClassNames } from '../../functions'
import { TagsNoRef, ToReferences } from '../../types'

import { ColorVariant, colorVariant } from '../../style/atoms'
import { svg } from './style.css'
import { getNamespace, getSize } from '../functions'

type VNodes = {
  svgRef: SVGSVGElement
  svgUseRef: SVGUseElement
}

type References = Partial<ToReferences<VNodes>>

type UseProps = Omit<TagsNoRef<'use'>, 'href'>
type SvgProps = Omit<TagsNoRef<'svg'>, 'color'>

export type IconPropsReferences = References

export type IconProps = SvgProps & {
  namespaceID?: string
  namespace: Namespaces
  color?: keyof ColorVariant
  useProps?: UseProps
} & References

export const Icon: FC<IconProps> = ({
  namespace,
  namespaceID,
  color = 'inherit',
  useProps,
  svgRef,
  svgUseRef,
  ...svgProps
}) => (
  <svg
    ref={svgRef}
    {...getSize(namespace)}
    {...propsWithClassNames(svgProps, colorVariant[color], svg)}
  >
    <use
      ref={svgUseRef}
      href={`#${getNamespace(namespace, namespaceID)}`}
      {...useProps}
    />
  </svg>
)

export default Icon
