import { useRequest } from 'ahooks'

import lazyToast from '$services/lazyToast'
import { MobileOTPResendRequest } from '$services/api/auth'
import api from '$model/api'

const { mobileOTPResend, errorMessageResolver, successResolver } =
  api.value.auth

export const useMobileOTPResend = () => {
  const { loading, runAsync } = useRequest(mobileOTPResend, { manual: true })

  const resend = (data: MobileOTPResendRequest) => {
    return lazyToast(runAsync(data), {
      loading: 'Resending..',
      error: e => errorMessageResolver(e, 'Invalid credential'),
      success: 'OTP requested'
    }).then(successResolver)
  }

  return { resend, loading }
}

export default useMobileOTPResend
