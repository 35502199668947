import { useAutoAnimate } from '@formkit/auto-animate/react'
import { AutoAnimateOptions } from '@formkit/auto-animate'

import { GENERAL_DURATION } from '@genie-fintech/ui/constants/transitions'

export const useAutoTransition = (options?: AutoAnimateOptions) => {
  return useAutoAnimate({
    duration: GENERAL_DURATION,
    easing: 'ease-out',
    ...options
  })
}

export default useAutoTransition
