import { keys } from 'lodash-es'

import { ALL_NAMESPACES } from './info'
import { PathProps, SymbolProps } from './types'

export const NAMESPACES = keys(
  ALL_NAMESPACES
) as (keyof typeof ALL_NAMESPACES)[]

/** General svg path attributes */
export const pathProps: PathProps = {
  fill: 'currentColor',
  fillRule: 'evenodd',
  clipRule: 'evenodd'
}

/** General svg path attributes */
export const symbolProps: SymbolProps = {
  fill: 'none'
}
