import { FieldError } from 'react-hook-form'

type ValueType = FieldError | FieldError[]

type Error = ValueType | Record<string | number, ValueType>
type ReturnValue = string | undefined

export const resolveErrorMessage = (error?: Error): ReturnValue => {
  if (!error) return

  if (Array.isArray(error)) {
    const messages = error.map(err => resolveErrorMessage(err)).filter(Boolean)

    if (!messages.length) return

    return `${messages}`
  }

  if (typeof error?.message == 'string') {
    return error.message
  }

  const fieldErrors = Object.keys(error).map(name => error[name as never])

  return resolveErrorMessage(fieldErrors)
}
