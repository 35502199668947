import { useState } from 'react'
import { useCountDown } from 'ahooks'

export const useTimer = (ms: number) => {
  const getTime = () => Date.now() + ms

  const [targetDate, setTargetDate] = useState(getTime)
  const [timeLeft, info] = useCountDown({ targetDate })

  const active = timeLeft > 0

  const reset = () => setTargetDate(getTime)

  return { reset, active, ...info }
}

export default useTimer
