import { FC } from 'react'

import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { Icon } from '@genie-fintech/ui/icons'

import useSignal from '$actions/useSignal'
import { passwordRules } from '$store/settings'

import { icon, items, list, text } from './styles.css'

export type PasswordRulesProps = {
  disabled?: boolean
  invalidRules: string[]
}

export const PasswordGuide: FC<PasswordRulesProps> = ({
  invalidRules,
  disabled
}) => {
  const rules = useSignal(passwordRules)
  const formatted = rules
    .map(({ regex, ...rest }) => ({
      id: `${regex}`,
      ...rest
    }))
    .map(({ id, ...rest }) => ({
      id,
      invalid: disabled || trueOrUndefined(invalidRules.includes(id)),
      ...rest
    }))

  return (
    <ul className={list}>
      {formatted.map(({ id, description, invalid }) => (
        <li key={id} data-invalid={invalid} className={items}>
          <Icon
            namespace={invalid ? 'Warning' : 'TickCircle'}
            className={icon}
          />
          <span className={text}>{description}</span>
        </li>
      ))}
    </ul>
  )
}

export default PasswordGuide
