import { isEqual, isNull, isUndefined } from 'lodash-es'

import { LOCAL_STORAGE_KEY_SEPARATOR, NAMESPACE } from '$constants'

type StoredValue = ReturnType<typeof localStorage.getItem>

class Storage {
  key = NAMESPACE

  constructor(names: string[]) {
    this.key = [NAMESPACE, ...names].join(LOCAL_STORAGE_KEY_SEPARATOR)
  }

  public _watch = (
    callback: (value: StoredValue) => void,
    matcher = (oldValue: StoredValue, newValue: StoredValue) => {
      return oldValue === newValue
    }
  ) => {
    const onChange = (ev: StorageEvent) => {
      const keyMatch = ev.key === this.key
      const valueMatch = matcher(ev.oldValue, ev.newValue)

      if (!keyMatch || valueMatch) return

      callback(ev.newValue)
    }

    self.addEventListener('storage', onChange)

    return () => {
      self.removeEventListener('storage', onChange)
    }
  }
}

export class PrimitiveStorage<T> extends Storage {
  // To store as object. E.g: {value: 123}
  private accessor = 'value'

  private parse = <D>(data: StoredValue, defaultValue: D) => {
    if (isNull(data)) return defaultValue

    const decoded = JSON.parse(data)
    const value = decoded[this.accessor]

    if (!value) return defaultValue

    return value as T
  }

  public get = (defaultValue: T) => {
    const data = localStorage.getItem(this.key)

    return this.parse(data, defaultValue)
  }

  public set = (value: T) => {
    const encoded = JSON.stringify({
      [this.accessor]: value
    })

    localStorage.setItem(this.key, encoded)
  }

  public watch = (callback: (data: T) => void) => {
    return this._watch(
      value => {
        const data = this.parse(value, undefined)

        if (isUndefined(data)) return

        callback(data)
      },
      (o, n) => isEqual(this.parse(o, undefined), this.parse(n, undefined))
    )
  }
}

export class ObjectStorage<T extends object> extends Storage {
  private parse = <D>(data: StoredValue, defaultValue: D) => {
    if (isNull(data)) return defaultValue

    return JSON.parse(data) as T
  }

  public get = (defaultValue: T) => {
    const data = localStorage.getItem(this.key)

    return this.parse(data, defaultValue)
  }

  public set = (value: T) => {
    localStorage.setItem(this.key, JSON.stringify(value))
  }

  public watch = (callback: (data: T) => void) => {
    return this._watch(
      value => {
        const data = this.parse(value, undefined)

        if (isUndefined(data)) return

        callback(data)
      },
      (o, n) => isEqual(this.parse(o, undefined), this.parse(n, undefined))
    )
  }
}
