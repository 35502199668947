import { FC, PropsWithChildren } from 'react'

import { Spinner } from '@genie-fintech/ui/components'

import { isAllTrue } from '$app/utilities'

import useSignal from '$actions/useSignal'
import { status } from '$store/status'

import { loaderControl } from './style.css'

/**
 * Views Controller
 */
export const Views: FC<PropsWithChildren> = ({ children }) => {
  const { isApiPrepared, isQueryParamsCached, isSettingsReady } =
    useSignal(status)

  const indicators = [isApiPrepared, isQueryParamsCached, isSettingsReady]

  const isReady = isAllTrue(indicators)

  if (!isReady)
    return (
      <article className={loaderControl}>
        <Spinner />
      </article>
    )

  return children
}

export default Views
