import { useEffect } from 'react'

import { useRouter } from '$actions/useRouter'
import { getAppTitle } from '$app/utilities'

export const useRouteMeta = () => {
  const { is404, info } = useRouter()

  useEffect(() => {
    if (!info?.meta) return

    const { title } = { ...info?.meta }

    const value = is404 ? 'Page not found' : title

    document.title = getAppTitle({ title: value })
  }, [info, is404])
}

export default useRouteMeta
