import { FC, PropsWithChildren, Ref } from 'react'

import {
  ButtonVariants as ButtonStyle,
  button as buttonStyle
} from '../../style/element'

import { propsWithClassNames } from '../../functions'
import { TagsNoRef } from '../../types'

type Props = {
  styleVariants?: ButtonStyle
  buttonRef?: Ref<HTMLButtonElement>
} & TagsNoRef<'button'>

export type ButtonProps = PropsWithChildren<Props>

export const Button: FC<ButtonProps> = ({
  styleVariants,
  buttonRef,
  ...tagProps
}) => (
  <button
    type="button"
    ref={buttonRef}
    {...propsWithClassNames(tagProps, buttonStyle(styleVariants))}
  />
)

export default Button
