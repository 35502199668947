import { AnyZodObject, z } from 'zod'

import { requiredString } from '$hook-forms/general-validators'

export const baseSchema = z.object({
  confirm_password: requiredString,
  password: requiredString
})

export type TFields = z.infer<typeof baseSchema>

export const defaultValues: TFields = {
  confirm_password: '',
  password: ''
}

export const refineSchema = <Schema extends AnyZodObject>(schema: Schema) => {
  return schema.refine(
    ({ confirm_password, password }) => password === confirm_password,
    {
      message: "Password doesn't match",
      path: ['confirm_password']
    }
  )
}
