import { FC, PropsWithChildren } from 'react'

import { Spinner } from '@genie-fintech/ui/components'

import useSignal from '$actions/useSignal'

import { profile } from '$store/profile'

/**
 * Wait until profile data arrived.
 */
export const ProfileWaiter: FC<PropsWithChildren> = ({ children }) => {
  const info = useSignal(profile)
  const isLoading = info?.loading
  const hasData = !!info?.data

  if (!isLoading && hasData) return children

  return (
    <Spinner
      style={{
        width: 'auto',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
      }}
    />
  )
}

export default ProfileWaiter
