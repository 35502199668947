import { FC, ReactNode } from 'react'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { BaseText, Password } from '@genie-fintech/ui/components/hook-fields'

import { loginFormPlaceholderText } from '$constants/texts'
import { TypeSubmitForm } from '$hook-forms/types'
import { requiredString } from '$hook-forms/general-validators'

import { submit } from '$forms/common/style.css'

import { container } from './styles.css'

const Schema = z.object({
  email: requiredString.email(),
  password: requiredString
})
const resolver = zodResolver(Schema)

type TFields = z.infer<typeof Schema>

const defaultValues: TFields = {
  email: '',
  password: ''
}

export type LoginFormFields = TFields

export type LoginFormProps = TypeSubmitForm<TFields> & {
  preFooter?: ReactNode
}

export const LoginForm: FC<LoginFormProps> = ({
  onSuccess,
  disabled,
  preFooter
}) => {
  const { control, reset, handleSubmit, formState } = useForm<TFields>({
    resolver,
    defaultValues,
    mode: 'all'
  })

  const isInvalid = disabled || !!Object.keys(formState.errors).length

  return (
    <form
      className={container}
      onSubmit={handleSubmit((...handler) => onSuccess(reset, ...handler))}
      noValidate
      autoComplete="off"
    >
      <BaseText
        control={control}
        name="email"
        label="Email"
        inputProps={{ placeholder: loginFormPlaceholderText.email }}
        required
        disabled={disabled}
      />
      <Password
        control={control}
        name="password"
        label="Password"
        required
        disabled={disabled}
      />

      {preFooter}

      <button
        name="login"
        type="submit"
        className={submit}
        disabled={isInvalid}
      >
        Login
      </button>
    </form>
  )
}

export default LoginForm
