import { useRequest } from 'ahooks'

import lazyToast from '$services/lazyToast'
import { MobileOTPVerifyRequest } from '$services/api/auth'
import api from '$model/api'

import { addToken } from '$store/session'

import { useSuccessLogin } from './useSuccessLogin'

const { mobileOTPVerify, errorMessageResolver, successResolver } =
  api.value.auth

export const useMobileOTPVerify = () => {
  const onLoginSuccess = useSuccessLogin()

  const { loading, runAsync } = useRequest(mobileOTPVerify, { manual: true })

  const verify = async (data: MobileOTPVerifyRequest) => {
    const {
      data: { auth_token }
    } = await lazyToast(runAsync(data), {
      loading: 'Verifying..',
      error: e => errorMessageResolver(e, 'Invalid credential'),
      success: 'OTP requested'
    }).then(successResolver)

    const id = addToken(auth_token)

    onLoginSuccess(id)
  }

  return { verify, loading }
}

export default useMobileOTPVerify
