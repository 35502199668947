import { FC, PropsWithChildren, useImperativeHandle, useRef } from 'react'
import { useSize } from 'ahooks'

import { TagsNoRef, ToReferences } from '@genie-fintech/ui/types'
import { propsWithClassNames } from '@genie-fintech/ui/functions'

import { valueOrUndefined } from '$app/utilities'

import { ContainerVariants, container } from './styles.css'

type VNodes = {
  containerRef: HTMLDivElement
  contentRef: HTMLDivElement
}

type References = Partial<ToReferences<VNodes>>

export type TabContentTransitionProps = TagsNoRef<'div'> &
  References & {
    styleVariants?: ContainerVariants
  }

export const TabContentTransition: FC<
  PropsWithChildren<TabContentTransitionProps>
> = ({
  children,
  style,
  styleVariants,
  contentRef,
  containerRef,
  ...restProps
}) => {
  const contentNode = useRef<VNodes['contentRef']>(null)
  const size = useSize(contentNode)

  useImperativeHandle(contentRef, () => contentNode.current as never)

  return (
    <div
      ref={containerRef}
      style={{
        ...style,
        maxHeight: valueOrUndefined(styleVariants?.heightEffect, size?.height)
      }}
      {...propsWithClassNames(restProps, container(styleVariants))}
    >
      <div ref={contentNode}>{children}</div>
    </div>
  )
}

export default TabContentTransition
