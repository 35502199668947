import { useBoolean, useRequest } from 'ahooks'

import useSignal from '$actions/useSignal'

import session from '$store/session'
import { api } from '$model/api'

const request = () => {
  const { listPublicApps, successResolver } = api.value.auth

  return listPublicApps().then(successResolver)
}

export const useAppList = () => {
  const { current: sessionID } = useSignal(session)

  // Track initial loading
  const [isDirty, { setTrue }] = useBoolean()

  const { data, loading, error } = useRequest(request, {
    // https://github.com/alibaba/hooks/issues/2356#issue-1977838612
    // cacheKey: `${sessionID}:app-list`,

    refreshDeps: [sessionID],
    refreshOnWindowFocus: true,
    onFinally: setTrue
  })
  const { data: list = [] } = { ...data }

  const formatted = list.map(({ id, ...rest }, key) => ({
    id: id || key,
    ...rest
  }))

  const isLoading = !isDirty && loading
  const hasError = !!error === true
  const isEmpty = formatted.length === 0 || hasError

  return {
    isLoading,
    isEmpty,
    formatted
  }
}

export default useAppList
