import { RouteNames } from '$app/router'
import { useRouter } from '$actions/useRouter'
import { useRouteSummary } from '$contexts/Router'

/**
 * Redirect to whatever current URL's `return_to` query parameter.
 */
export const useReturnTo = () => {
  const { queryParams, redirect } = useRouter()
  const { details } = useRouteSummary()

  const { return_to } = { ...queryParams }

  const isValid = (() => {
    if (!return_to) return false

    return Object.keys(details).includes(return_to)
  })()

  type Options = Parameters<typeof redirect>[1]

  return (fallback: RouteNames, options?: Options) => {
    const destination = return_to || fallback

    // Only redirect to valid route-names
    // otherwise, use fallback
    const target = isValid ? destination : fallback

    redirect(target, options)
  }
}

export default useReturnTo
