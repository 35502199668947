import { FC } from 'react'

import usePageClass from '$browser/usePageClass'

import ForgotPasswordBlock from '$blocks/ForgotPasswordBlock'

const pageName = 'ForgotPassword'

export const ForgotPassword: FC = () => {
  usePageClass({ name: pageName })

  return <ForgotPasswordBlock />
}

export default ForgotPassword
