import { trim } from 'lodash-es'

import { Path, PropsWithClassName } from './types'

/** Flattens plain objects. */
export const toDotNotation = <V extends object, RecordValue extends never>(
  obj: V
) => {
  const result = {} as Record<Path<V>, RecordValue>

  for (const key of Object.keys(obj)) {
    // @ts-ignore
    const value = obj[key]

    if (typeof value === 'object') {
      const nested = toDotNotation(value)

      // @ts-ignore
      for (const nestedKey of Object.keys(nested)) {
        // @ts-ignore
        result[`${key}.${nestedKey}`] = nested[nestedKey]
      }
    } else {
      // @ts-ignore
      result[key] = value
    }
  }

  return result
}

export const toClassNames = (...args: (string | undefined)[]) => {
  // @ts-ignore
  return args.filter(Boolean).map(trim).join(' ')
}

/**
 * *Example usage:*
 * ```tsx
 * const props = { className: 'original' }
 * const otherClass1 = style({ color: 'white' })
 * const otherClass2 = style({ backgroundColor: 'red' })
 *
 * <div {...propsWithClassNames(props, otherClass1, otherClass2)} />
 * // same as the following 👇
 * <div {...props} className={`${otherClass1} ${otherClass2} ${props.className}`}  />
 * ```
 */

export const propsWithClassNames = <T extends PropsWithClassName | undefined>(
  /** Props containing original `className`. */
  props: T,

  /** List of `className` to be injected before the original `className`. */
  ...classNames: Parameters<typeof toClassNames>
) => ({
  ...props,
  className: toClassNames(...classNames, props?.className)
})

export const trueOrUndefined = <T>(value: T) => (value ? true : undefined)
