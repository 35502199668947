import { useState, useEffect } from 'react'
import { Primitive } from 'type-fest'

type State<T extends Primitive> = { value: T }

type Subscriber<T extends Primitive> = (currentValue: T) => void

type Mutator<T extends Primitive> = (
  currentState: State<T>
) => State<T>['value']

/**
 * Accepts `new` value.
 *
 * Also accepts a function to access `old` value.
 */
type NewValue<T extends Primitive> = T | ((old: T) => T)

/**
 * Triggers subscriptions. Default: `true`
 */
type Notify = boolean

export class CreateAtom<TData extends Primitive> {
  #_subscribers: Subscriber<TData>[]

  #data: State<TData>

  constructor(data: TData) {
    this.#_subscribers = []
    this.#data = { value: data }
  }

  #notify = () => {
    const data = this.data

    this.#_subscribers.forEach(fire => fire(data))
  }

  get data() {
    return this.#data.value
  }

  mutate = (mutator: Mutator<TData>, notify: Notify = true) => {
    const newValue = mutator(this.#data)

    if (notify) {
      this.#notify()
    }

    return newValue
  }

  update = (
    newValue: NewValue<TData>,

    notify: Notify = true
  ) => {
    const newData =
      typeof newValue == 'function' ? newValue(this.data) : newValue

    this.#data = { value: newData }

    if (notify) {
      this.#notify()
    }

    return newData
  }

  subscribe = (subscriber: Subscriber<TData>) => {
    this.#_subscribers.push(subscriber)

    const cleanup = () => {
      const index = this.#_subscribers.indexOf(subscriber)

      this.#_subscribers.splice(index, 1)
    }

    return cleanup
  }
}

/**
 * `React` binding hook. Synchronize with `useState`.
 */
export const useAtom = <T extends Primitive>({
  data,
  subscribe,
  ...rest
}: CreateAtom<T>) => {
  const [state, setState] = useState(data)

  useEffect(() => {
    const cleanup = subscribe(setState)

    return cleanup
  }, [subscribe])

  return { state, ...rest }
}

export default CreateAtom
