import { useBoolean, useMount } from 'ahooks'

export const useIsMounted = () => {
  const [status, { setTrue }] = useBoolean()

  useMount(setTrue)

  return status
}

export default useIsMounted
