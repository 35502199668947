import { useController, ControllerRenderProps } from 'react-hook-form'

import BaseField, {
  PasswordProps as BaseFieldProps
} from '../../fields/Password'

import { HookField } from '../common/types'
import { resolveErrorMessage } from '../common/functions'

type InputProps = Required<BaseFieldProps>['inputProps']

export type PasswordProps = Omit<BaseFieldProps, 'inputProps' | 'inputRef'> & {
  inputProps?: Omit<InputProps, keyof ControllerRenderProps>
}

export const Password: HookField<PasswordProps> = ({
  name,
  control,
  disabled,
  controllerOptions,
  ...fieldProps
}) => {
  const { field, fieldState } = useController({
    name,
    control,
    disabled,
    ...controllerOptions
  })

  const { value = '', ref: inputRef, ...controlledFieldProps } = field

  const errorMessage = resolveErrorMessage(fieldState.error)
  const hasError = !!errorMessage

  const fieldPropBindings: BaseFieldProps = {
    disabled,
    error: hasError,
    message: errorMessage,
    ...fieldProps,
    inputRef,
    inputProps: {
      ...fieldProps.inputProps,
      ...controlledFieldProps,
      value
    }
  }

  return <BaseField {...fieldPropBindings} />
}

export default Password
