import { FC } from 'react'

import { AuthLogoProps } from './types'

const Genie: FC<AuthLogoProps> = ({ color = 'white', ...props }) => (
  <svg width={129} height={29} viewBox="0 0 129 29" fill="none" {...props}>
    <g clipPath="url(#clip0_803_573)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.9019 7.71779L60.2673 11.8559C60.5833 11.0556 60.7788 10.2347 60.7935 9.41731L54.5825 5.92773L52.9019 7.71779Z"
        fill={color}
      />
      <mask
        id="mask0_803_573"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x={47}
        y={0}
        width={10}
        height={11}
      >
        <path
          d="M47.4712 0.214844H56.2989V10.2596H47.4712V0.214844Z"
          fill={color}
        />
      </mask>
      <g mask="url(#mask0_803_573)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.905 0.214844C52.5336 1.179 47.4712 4.99434 47.4712 8.76244C47.4712 9.26195 47.5568 9.76323 47.7084 10.2597L56.2989 2.12461C55.3004 1.22579 54.3806 0.549031 53.905 0.214844Z"
          fill={color}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.875 4.72363L60.0045 7.41042C59.614 5.65196 58.3269 3.94544 57.0197 2.59375L54.875 4.72363Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8579 6.13867C13.2982 6.13867 15.4091 6.83004 16.9449 7.72289V12.3316C15.2721 11.1217 13.1061 10.2577 10.94 10.2577C6.9369 10.2577 4.35962 13.2245 4.35962 16.9403C4.35962 20.5693 6.82736 23.6226 10.9125 23.6226C11.8172 23.6226 12.695 23.4498 13.3529 23.1619V20.0221H9.89801V16.2201H17.5755V25.8982C15.7932 26.935 13.2982 27.6264 10.8304 27.6264C4.46916 27.6264 0 23.1041 0 16.969C0 10.8049 4.52415 6.13867 10.8579 6.13867Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.462 21.4701C24.9628 23.399 26.6119 24.2785 28.6735 24.2785C30.2933 24.2785 32.0605 23.7394 33.5914 22.7467V26.3494C32.1781 27.2003 29.9989 27.6259 28.0845 27.6259C23.3135 27.6259 19.8677 24.7608 19.8677 20.3921C19.8677 15.9385 23.4607 13.0449 27.466 13.0449C31.0297 13.0449 34.3866 15.3143 34.3866 19.995C34.3866 20.4772 34.3573 21.1012 34.2985 21.4701H24.462ZM24.4324 18.7467H30.2637C29.9989 17.2434 28.85 16.3356 27.4071 16.3356C26.0227 16.3356 24.8156 17.1583 24.4324 18.7467Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.9057 21.4701C62.4063 23.399 64.0556 24.2785 66.1171 24.2785C67.7369 24.2785 69.5041 23.7394 71.0352 22.7467V26.3494C69.6215 27.2003 67.4425 27.6259 65.5282 27.6259C60.7573 27.6259 57.3115 24.7608 57.3115 20.3921C57.3115 15.9385 60.9045 13.0449 64.9099 13.0449C68.4735 13.0449 71.8304 15.3143 71.8304 19.995C71.8304 20.4772 71.8009 21.1012 71.7421 21.4701H61.9057ZM61.8764 18.7467H67.7074C67.4425 17.2434 66.2939 16.3356 64.8511 16.3356C63.4666 16.3356 62.2594 17.1583 61.8764 18.7467Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.1034 16.8519C53.3216 16.8519 52.5902 16.6506 51.9624 16.301V27.6265H56.5473V16.1152C55.857 16.5785 55.014 16.8519 54.1034 16.8519Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.2259 15.1175C47.8785 14.4949 47.3881 14.0214 46.8599 13.6893C46.5221 13.4811 46.1521 13.3201 45.7569 13.2111V13.2052C45.6504 13.1769 45.545 13.1535 45.4422 13.136C44.5807 12.9897 43.8567 12.9951 42.8247 13.3359C42.0003 13.6907 41.2573 14.2396 40.717 14.7036C40.6219 14.7859 40.4095 15.0069 40.2674 15.157C40.0278 15.447 39.5246 15.985 38.9702 16.1483C37.6635 16.6161 36.6792 16.0112 36.6792 16.0112V16.3258V27.6259H40.5834V18.0294C41.2769 17.4207 42.2273 16.8409 43.2291 16.8409C43.7683 16.8409 44.2307 17.0149 44.5647 17.4495C44.873 17.8556 45.0014 18.3773 45.0014 19.45V27.6259H48.9057V18.7252C48.9057 17.095 48.6959 15.9743 48.2259 15.1175ZM38.2572 16.0011C38.1784 16.0018 38.1047 16.0002 38.0346 15.9964H38.3805C38.3395 15.9988 38.2982 16.0007 38.2572 16.0011Z"
        fill={color}
      />
      <path
        d="M78.4275 27.8487H76.561L83.6556 8.3457H85.5602L92.6549 27.8487H90.7884L84.6746 10.6598H84.5413L78.4275 27.8487ZM79.7703 20.3732H89.4456V21.9731H79.7703V20.3732Z"
        fill={color}
      />
      <path
        d="M103.867 22.259V13.2217H105.562V27.8489H103.867V25.4206H103.733C103.391 26.1697 102.848 26.7951 102.105 27.2966C101.362 27.7918 100.454 28.0394 99.3814 28.0394C98.4481 28.0394 97.6196 27.8362 96.8959 27.4299C96.1722 27.0173 95.604 26.4142 95.1913 25.6206C94.7786 24.827 94.5723 23.8525 94.5723 22.697V13.2217H96.2674V22.5923C96.2674 23.735 96.6039 24.6556 97.2768 25.3539C97.9498 26.0459 98.8132 26.3919 99.8671 26.3919C100.527 26.3919 101.162 26.2332 101.772 25.9158C102.381 25.5983 102.883 25.1349 103.276 24.5254C103.67 23.9096 103.867 23.1541 103.867 22.259Z"
        fill={color}
      />
      <path
        d="M114.738 13.2213V14.6973H107.758V13.2213H114.738ZM109.939 9.7168H111.643V24.0298C111.643 24.6393 111.748 25.1186 111.958 25.4678C112.167 25.8106 112.44 26.0551 112.777 26.2011C113.113 26.3407 113.472 26.4106 113.853 26.4106C114.075 26.4106 114.265 26.3979 114.424 26.3725C114.583 26.3407 114.722 26.309 114.843 26.2773L115.205 27.8105C115.04 27.8739 114.837 27.9311 114.595 27.9819C114.354 28.039 114.056 28.0676 113.7 28.0676C113.078 28.0676 112.478 27.9311 111.9 27.6581C111.329 27.3851 110.859 26.9788 110.491 26.4391C110.123 25.8995 109.939 25.2297 109.939 24.4298V9.7168Z"
        fill={color}
      />
      <path
        d="M119.525 18.7067V27.8487H117.83V8.3457H119.525V15.5165H119.677C120.02 14.761 120.55 14.1579 121.267 13.7071C121.991 13.2564 122.905 13.031 124.01 13.031C125 13.031 125.87 13.2342 126.619 13.6405C127.368 14.0468 127.953 14.6467 128.372 15.4403C128.791 16.2339 129 17.2116 129 18.3734V27.8487H127.295V18.4781C127.295 17.2719 126.959 16.3228 126.286 15.6308C125.619 14.9324 124.718 14.5832 123.581 14.5832C122.801 14.5832 122.105 14.7483 121.496 15.0784C120.886 15.4086 120.404 15.8847 120.048 16.5069C119.699 17.1227 119.525 17.856 119.525 18.7067Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_803_573">
        <rect width={129} height={29} fill={color} />
      </clipPath>
    </defs>
  </svg>
)

export default Genie
