import { FC, PropsWithChildren, ReactNode } from 'react'

import { propsWithClassNames, toClassNames } from '@genie-fintech/ui/functions'
import { TagsNoRef } from '@genie-fintech/ui/types'

import {
  container,
  header,
  nav as navStyle,
  info,
  description as descriptionStyle,
  title as titleStyle
} from './styles.css'

type ElementProps = {
  containerProps?: TagsNoRef<'article'>
  mainProps?: TagsNoRef<'main'>
  navProps?: TagsNoRef<'nav'>
}

type ClassNameProps = Partial<
  Record<'headerClassName' | 'mainClassName', string>
>

type DataProps = {
  title?: ReactNode
  description?: ReactNode
  nav?: ReactNode
}

export type ContentCardProps = ElementProps & DataProps & ClassNameProps

export const ContentCard: FC<PropsWithChildren<ContentCardProps>> = ({
  children,
  containerProps,
  mainProps,
  navProps,
  nav,
  description,
  title,
  headerClassName,
  mainClassName
}) => (
  <article {...propsWithClassNames(containerProps, container)}>
    {(description || title || nav) && (
      <header className={toClassNames(header, headerClassName)}>
        {(description || title) && (
          <div className={info}>
            {title && <h2 className={titleStyle}>{title}</h2>}

            {description && (
              <div role="definition" className={descriptionStyle}>
                {description}
              </div>
            )}
          </div>
        )}

        {nav && (
          <nav children={nav} {...propsWithClassNames(navProps, navStyle)} />
        )}
      </header>
    )}

    {children && (
      <main
        children={children}
        {...propsWithClassNames(mainProps, mainClassName)}
      />
    )}
  </article>
)

export default ContentCard
