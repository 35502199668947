import { FC, PropsWithChildren, useEffect } from 'react'

import { Spinner } from '@genie-fintech/ui/components'

import useRouter from '$actions/useRouter'
import useSignal from '$actions/useSignal'

import queryParams, { clearQueryParamCache } from '$store/queryParams'
import session, { updateCurrent } from '$store/session'
import { status } from '$store/status'
import { activeSource } from '$model/api'

/**
 * Redirects to `authAccounts` when session is inactive. Otherwise, render the children.
 * - When url contain `?auth_user=...` query param, its value will be validated and marked as current `active` session.
 */
export const AccountSelectionGuard: FC<PropsWithChildren> = ({ children }) => {
  const apiActiveSource = useSignal(activeSource)
  const { hasApiActiveSource, hasActiveSession } = useSignal(status)

  const {
    redirectWithReturnTo,
    name,
    queryParams: urlQueryParams,
    updateQueryParams
  } = useRouter()

  const { params } = useSignal(queryParams)
  const { tokenStates } = useSignal(session)

  // Favor `urlQueryParams` before using cached `queryParams`
  const requestedAccountId = urlQueryParams.auth_user || params.auth_user
  const requestedAccountState = tokenStates.find(
    ({ id }) => requestedAccountId == id
  )?.state
  const hasRequestedAccountId = !!requestedAccountId && !!requestedAccountState
  const requestedAccountIsChecked = !!requestedAccountState?.checked
  const requestedAccountIsAlive = !!requestedAccountState?.alive
  const isRequestedAccountIsValidating = (() => {
    return hasRequestedAccountId && !requestedAccountIsChecked
  })()
  const requestedAccountIsValid = (() => {
    return !isRequestedAccountIsValidating && requestedAccountIsAlive
  })()
  const requestedAccountApiIsPreparing = (() => {
    const isApiActive = apiActiveSource?.id === requestedAccountId

    return requestedAccountIsValid && !isApiActive
  })()

  const isAccountSelected = hasApiActiveSource && hasActiveSession

  const isLoading = (() => {
    return (
      requestedAccountApiIsPreparing ||
      isRequestedAccountIsValidating ||
      !isAccountSelected
    )
  })()

  // When the target session is invalid,
  // Redirect to account selection.
  useEffect(() => {
    // Ignore 404
    if (!name) return

    // Wait until fully validated when automatic user selection is requested.
    if (isRequestedAccountIsValidating) return

    if (!isAccountSelected) {
      redirectWithReturnTo(
        'authAccounts',
        name,
        // Keep query-params
        ({ queryParams }) => ({ queryParams })
      )

      return
    }
  }, [
    hasRequestedAccountId,
    requestedAccountIsChecked,
    requestedAccountIsAlive,
    isAccountSelected,
    name,
    redirectWithReturnTo,
    isRequestedAccountIsValidating
  ])

  // Auto-select `requestedAccountId` when session is `alive`
  useEffect(() => {
    if (!requestedAccountIsValid) return

    updateCurrent(requestedAccountId)

    // Clear `auth_user` cache after `requestedAccountId` is being applied.
    // Since it is no longer relevant.
    clearQueryParamCache(['auth_user'])
    // Also remove the query with soft redirect
    updateQueryParams({ auth_user: undefined })
  }, [updateQueryParams, requestedAccountIsValid, requestedAccountId])

  if (!isLoading) return children

  return (
    <Spinner
      style={{
        width: 'auto',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
      }}
    />
  )
}

export default AccountSelectionGuard
