import { usePrevious } from 'ahooks'
import { useEffect } from 'react'

import api, { Sources } from '$model/api'
import { setApiPrepared } from '$store/status'

import useSignal from './useSignal'

export const usePrepareApi = () => {
  const sources = useSignal(api)
  const prevSources = usePrevious(sources)

  // Clean up previously subscribed API-effects upon changes
  useEffect(() => {
    if (prevSources) {
      const sourceNames = Object.keys(prevSources)

      sourceNames.map(name => {
        const source = prevSources[name as keyof Sources]

        source.destroyErrorResponseWatchers()
      })
    }

    setApiPrepared(true)
  }, [prevSources])
}

export default usePrepareApi
