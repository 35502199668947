import { FC, PropsWithChildren } from 'react'

import { TagsNoRef } from '@genie-fintech/ui/types'
import { propsWithClassNames } from '@genie-fintech/ui/functions'

import { container, overlay } from './style.css'

export const BgCover: FC<PropsWithChildren<TagsNoRef<'span'>>> = ({
  children,
  ...props
}) => (
  <span {...propsWithClassNames(props, container)}>
    <span className={overlay} />
    {children}
  </span>
)

export default BgCover
