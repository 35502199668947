import { useEffect } from 'react'

import { useRouter } from '$actions/useRouter'

import session from '$store/session'
import { status } from '$store/status'

import useSignal from './useSignal'

/**
 * Redirect to login when no active tokens available
 */
export const useAutoRequestLogin = () => {
  const { redirectWithQueryParams } = useRouter()

  const { tokensChecked } = useSignal(status)
  const { tokenDetails } = useSignal(session)

  // Redirect to login when no active tokens available
  useEffect(() => {
    if (tokensChecked && !tokenDetails.length) {
      redirectWithQueryParams('authLogin')
    }
  }, [tokensChecked, tokenDetails, redirectWithQueryParams])
}

export default useAutoRequestLogin
