import { useEffect } from 'react'

export type TypeUseLayoutClass = {
  name: string
  prefix?: string
}

export const useLayoutClass = ({
  name,
  prefix = 'ActiveLayout'
}: TypeUseLayoutClass) => {
  const HTML = document.documentElement

  useEffect(() => {
    if (!name) return

    const LayoutClass = `${prefix}--${name}`

    HTML.classList.add(LayoutClass)

    return () => {
      HTML.classList.remove(LayoutClass)
    }
  }, [prefix, name, HTML.classList])
}

export default useLayoutClass
