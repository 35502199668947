import { pick } from 'lodash-es'

import { ID } from '../constants'

import { NSInfo, Namespaces, SymbolProps } from './types'
import { ALL_NAMESPACES } from './info'
import { NAMESPACES } from './constants'

export const createNamespaces = <T extends string>(
  items: Record<T, NSInfo>
) => {
  return items
}

export const getViewBox = (ns: Namespaces) => ALL_NAMESPACES[ns].viewBox
export const getSize = (ns: Namespaces) =>
  pick(ALL_NAMESPACES[ns], ['width', 'height'])

export const getNamespace = (ns: Namespaces, id: string = ID) =>
  `${id}-${NAMESPACES.find(name => name === ns)}`

export const getSymbolProps = (
  ns: Namespaces,
  id: string = ID
): SymbolProps => ({
  viewBox: getViewBox(ns),
  id: `${id}-${NAMESPACES.find(name => name === ns)}`
})
