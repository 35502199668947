import { FC } from 'react'

import { Icon } from '@genie-fintech/ui/icons'
import { toClassNames } from '@genie-fintech/ui/functions'

import { bold, container, highlight } from './styles.css'

export const Message: FC = () => (
  <header role="banner" className={container}>
    <figure>
      <Icon namespace="Warning" />
    </figure>

    <main>
      <span className={bold}>
        NOTE ONLY FOR <span className={highlight}>CAPtain USERS:</span>
      </span>
      <br />
      You can use the current{' '}
      <span className={toClassNames(bold, highlight)}>
        CAPtain email and password
      </span>{' '}
      to login.
    </main>
  </header>
)

export default Message
