import { FC } from 'react'

import { AuthLogoProps } from './types'

const Carro: FC<AuthLogoProps> = ({ color = 'white', ...props }) => (
  <svg width={218} height={40} viewBox="0 0 218 40" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.5353 12.6804C28.2426 7.9791 24.4139 6.06152 18.3207 6.06152C13.6462 6.06152 9.24776 7.82824 5.93565 11.0362C2.53788 14.3271 0.666626 18.813 0.666626 23.6679C0.666626 32.9789 7.5592 40 16.6992 40C22.3899 40 26.6294 38.1927 30.0413 34.3119C30.1822 34.1518 30.2506 33.9382 30.2292 33.7263C30.2075 33.5139 30.0973 33.3179 29.9262 33.1877L25.4176 29.7668C25.0853 29.5138 24.6131 29.5662 24.3432 29.8858C22.489 32.0808 19.9977 33.1933 16.9377 33.1933C12.0768 33.1933 8.54901 29.207 8.54901 23.7152C8.54901 20.7494 9.49909 18.0153 11.224 16.0168C12.9766 13.9863 15.4292 12.8685 18.13 12.8685C21.2672 12.8685 23.6204 14.1262 25.3247 16.7136C25.438 16.8857 25.6114 17.0044 25.8132 17.048C26.0189 17.0922 26.2289 17.0549 26.4049 16.9411L31.3194 13.7724C31.4953 13.6591 31.6195 13.4766 31.66 13.2718C31.7005 13.0669 31.6551 12.8514 31.5353 12.6804Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M77.3811 22.4149C83.4386 22.4149 86.9486 19.7341 86.9486 13.8017C86.9486 8.72506 83.8345 6.78571 78.4001 6.78571H77.4719C77.2883 6.78571 77.1312 6.91884 77.0997 7.10101L74.5152 21.9682C74.4746 22.2012 74.6526 22.4149 74.8874 22.4149H77.3811ZM91.6418 38.7869H84.3448C84.2049 38.7869 84.0763 38.7089 84.0107 38.5841L79.1383 29.3109C79.0677 29.1767 78.9255 29.1007 78.7755 29.1117C78.2905 29.147 77.7996 29.147 77.2675 29.147H73.6217C73.4383 29.147 73.2815 29.2796 73.2495 29.4615L71.6616 38.4726C71.6295 38.6542 71.4727 38.7869 71.2894 38.7869H64.3008C64.0658 38.7869 63.8875 38.5729 63.9283 38.3397L70.5883 0.314799C70.6204 0.132627 70.7772 0 70.9608 0H79.192C89.0994 0 94.9303 5.07591 94.9303 13.8034C94.9303 19.3819 92.3857 24.6875 86.5541 26.9995C86.344 27.0826 86.2479 27.3273 86.3536 27.5283L91.9759 38.2276C92.1093 38.4815 91.9267 38.7869 91.6418 38.7869Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M116.559 16.2348C116.559 21.1211 113.643 23.5985 107.892 23.5985H105.55C105.539 23.5985 105.531 23.5948 105.523 23.5866C105.517 23.5789 105.514 23.5697 105.516 23.5596L107.944 10.3207C107.946 10.3048 107.961 10.2934 107.978 10.2934H108.85C114.109 10.2934 116.559 12.1812 116.559 16.2348ZM116.604 28.3125C116.601 28.3072 116.599 28.3013 116.602 28.2919C116.605 28.2859 116.611 28.2753 116.627 28.2693C121.756 26.3416 124.698 21.9546 124.698 16.2339C124.698 12.3339 123.311 9.1211 120.686 6.94297C118.079 4.77996 114.243 3.63647 109.594 3.63647H101.862C101.534 3.63647 101.254 3.86061 101.197 4.16954L94.9406 38.0296C94.9061 38.2183 94.9601 38.4102 95.0886 38.5565C95.2173 38.703 95.4058 38.7871 95.6059 38.7871H102.171C102.499 38.7871 102.779 38.5634 102.836 38.2545L104.327 30.2305C104.33 30.2149 104.344 30.2032 104.361 30.2032H107.786C108.286 30.2032 108.759 30.2032 109.226 30.1707C109.232 30.1705 109.252 30.1702 109.26 30.1861L113.837 38.4439C113.955 38.6557 114.183 38.7871 114.434 38.7871H121.288C121.527 38.7871 121.744 38.6703 121.867 38.4747C121.991 38.279 121.997 38.0418 121.885 37.8399L116.604 28.3125Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M143.837 12.8773C149.151 12.8773 152.721 16.6835 152.721 22.3486C152.721 28.9333 148.814 33.1881 142.767 33.1881C137.453 33.1881 133.883 29.3816 133.883 23.7167C133.883 17.1317 137.79 12.8773 143.837 12.8773ZM155.871 10.6813C152.815 7.70204 148.61 6.06152 144.031 6.06152C139.156 6.06152 134.644 7.74014 131.328 10.7882C127.802 14.0285 125.938 18.4493 125.938 23.5735C125.938 28.1768 127.632 32.3695 130.708 35.3797C133.752 38.359 137.948 40 142.524 40C147.402 40 151.922 38.3217 155.251 35.2739C158.794 32.031 160.667 27.6102 160.667 22.4888C160.667 17.8883 158.964 13.6949 155.871 10.6813Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.1425 28.7126L50.1988 16.0154L52.2406 28.7126H44.1425ZM62.662 38.0685L54.3325 4.11129C54.2643 3.83309 54.0058 3.63647 53.7082 3.63647H49.8166C49.5792 3.63647 49.3611 3.76243 49.2499 3.96401L30.5198 37.9215C30.4093 38.0973 30.406 38.318 30.511 38.497C30.616 38.676 30.8145 38.7871 31.0284 38.7871H37.5613C37.7709 38.7871 37.9647 38.6812 38.0718 38.5084L40.3619 34.8108H53.869L54.8032 38.3561C54.87 38.6095 55.1074 38.7871 55.3794 38.7871H62.0631C62.2267 38.7871 62.3903 38.734 62.5059 38.6227C62.6602 38.4737 62.7173 38.2659 62.662 38.0685Z"
      fill={color}
    />
    <path
      d="M217.333 28.5518C217.333 30.8846 216.912 32.9007 216.07 34.5999C215.227 36.2991 214.072 37.6096 212.603 38.5312C211.134 39.4528 209.456 39.9136 207.57 39.9136C205.683 39.9136 204.006 39.4528 202.537 38.5312C201.068 37.6096 199.913 36.2991 199.07 34.5999C198.228 32.9007 197.807 30.8846 197.807 28.5518C197.807 26.219 198.228 24.2029 199.07 22.5037C199.913 20.8045 201.068 19.494 202.537 18.5724C204.006 17.6508 205.683 17.19 207.57 17.19C209.456 17.19 211.134 17.6508 212.603 18.5724C214.072 19.494 215.227 20.8045 216.07 22.5037C216.912 24.2029 217.333 26.219 217.333 28.5518ZM214.741 28.5518C214.741 26.6366 214.421 25.0201 213.78 23.7025C213.146 22.3849 212.286 21.3877 211.199 20.7109C210.119 20.0341 208.909 19.6956 207.57 19.6956C206.231 19.6956 205.017 20.0341 203.93 20.7109C202.85 21.3877 201.99 22.3849 201.349 23.7025C200.715 25.0201 200.399 26.6366 200.399 28.5518C200.399 30.467 200.715 32.0835 201.349 33.4011C201.99 34.7187 202.85 35.7159 203.93 36.3927C205.017 37.0695 206.231 37.4079 207.57 37.4079C208.909 37.4079 210.119 37.0695 211.199 36.3927C212.286 35.7159 213.146 34.7187 213.78 33.4011C214.421 32.0835 214.741 30.467 214.741 28.5518Z"
      fill={color}
    />
    <path
      d="M193.907 23.0221C193.777 21.9277 193.251 21.0781 192.33 20.4733C191.408 19.8684 190.278 19.566 188.938 19.566C187.959 19.566 187.102 19.7244 186.368 20.0413C185.641 20.3581 185.072 20.7937 184.662 21.3481C184.258 21.9025 184.057 22.5325 184.057 23.2381C184.057 23.8285 184.197 24.3361 184.478 24.7609C184.766 25.1785 185.133 25.5277 185.58 25.8085C186.026 26.0822 186.494 26.309 186.984 26.489C187.473 26.6618 187.923 26.8022 188.334 26.9102L190.58 27.515C191.156 27.6662 191.797 27.875 192.503 28.1414C193.215 28.4078 193.896 28.7714 194.544 29.2322C195.199 29.6858 195.739 30.269 196.164 30.9818C196.589 31.6946 196.801 32.5695 196.801 33.6063C196.801 34.8015 196.488 35.8815 195.861 36.8463C195.242 37.8112 194.335 38.578 193.14 39.1468C191.952 39.7156 190.508 40 188.809 40C187.225 40 185.853 39.7444 184.694 39.2332C183.542 38.722 182.635 38.0092 181.972 37.0947C181.317 36.1803 180.946 35.1183 180.86 33.9087H183.625C183.697 34.7439 183.978 35.4351 184.467 35.9823C184.964 36.5223 185.59 36.9255 186.346 37.1919C187.11 37.4512 187.93 37.5808 188.809 37.5808C189.831 37.5808 190.749 37.4151 191.563 37.0839C192.377 36.7455 193.021 36.2775 193.496 35.6799C193.971 35.0751 194.209 34.3695 194.209 33.5631C194.209 32.8287 194.004 32.2311 193.593 31.7703C193.183 31.3094 192.643 30.935 191.973 30.647C191.304 30.359 190.58 30.107 189.802 29.891L187.081 29.1134C185.353 28.6166 183.985 27.9074 182.977 26.9858C181.969 26.0642 181.465 24.8581 181.465 23.3677C181.465 22.1293 181.8 21.0493 182.469 20.1277C183.146 19.1988 184.053 18.4788 185.191 17.9676C186.336 17.4492 187.614 17.19 189.025 17.19C190.45 17.19 191.718 17.4456 192.827 17.9568C193.935 18.4608 194.814 19.152 195.462 20.0305C196.117 20.9089 196.463 21.9061 196.499 23.0221H193.907Z"
      fill={color}
    />
    <path
      d="M177.047 23.0221C176.917 21.9277 176.391 21.0781 175.47 20.4733C174.548 19.8684 173.418 19.566 172.078 19.566C171.099 19.566 170.242 19.7244 169.508 20.0413C168.781 20.3581 168.212 20.7937 167.802 21.3481C167.398 21.9025 167.197 22.5325 167.197 23.2381C167.197 23.8285 167.337 24.3361 167.618 24.7609C167.906 25.1785 168.273 25.5277 168.72 25.8085C169.166 26.0822 169.634 26.309 170.124 26.489C170.613 26.6618 171.063 26.8022 171.474 26.9102L173.72 27.515C174.296 27.6662 174.937 27.875 175.643 28.1414C176.355 28.4078 177.036 28.7714 177.684 29.2322C178.339 29.6858 178.879 30.269 179.304 30.9818C179.729 31.6946 179.941 32.5695 179.941 33.6063C179.941 34.8015 179.628 35.8815 179.001 36.8463C178.382 37.8112 177.475 38.578 176.28 39.1468C175.092 39.7156 173.648 40 171.949 40C170.365 40 168.993 39.7444 167.834 39.2332C166.682 38.722 165.775 38.0092 165.112 37.0947C164.457 36.1803 164.086 35.1183 164 33.9087H166.765C166.837 34.7439 167.118 35.4351 167.607 35.9823C168.104 36.5223 168.73 36.9255 169.486 37.1919C170.25 37.4512 171.07 37.5808 171.949 37.5808C172.971 37.5808 173.889 37.4151 174.703 37.0839C175.517 36.7455 176.161 36.2775 176.636 35.6799C177.111 35.0751 177.349 34.3695 177.349 33.5631C177.349 32.8287 177.144 32.2311 176.733 31.7703C176.323 31.3094 175.783 30.935 175.113 30.647C174.444 30.359 173.72 30.107 172.942 29.891L170.221 29.1134C168.493 28.6166 167.125 27.9074 166.117 26.9858C165.109 26.0642 164.605 24.8581 164.605 23.3677C164.605 22.1293 164.94 21.0493 165.609 20.1277C166.286 19.1988 167.193 18.4788 168.331 17.9676C169.476 17.4492 170.754 17.19 172.165 17.19C173.591 17.19 174.858 17.4456 175.967 17.9568C177.075 18.4608 177.954 19.152 178.602 20.0305C179.257 20.9089 179.603 21.9061 179.639 23.0221H177.047Z"
      fill={color}
    />
  </svg>
)

export default Carro
