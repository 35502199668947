import { useCallback } from 'react'

import {
  on,
  redirect,
  onOneOf,
  RouteNames,
  ExtendedQueryParams
} from '$app/router'
import { useRoute } from '$contexts/Router'

type RedirectParams = Parameters<typeof redirect>
type RedirectWithQueryParams = (name: RedirectParams[0]) => void
type RedirectWithReturnTo = (
  name: RedirectParams[0],
  return_to: RouteNames,
  options?: RedirectParams[1]
) => void

/** With extended router actions. */
export const useRouter = () => {
  const { name, queryParams: originalQueryParams, ...rest } = useRoute()
  const queryParams = originalQueryParams as ExtendedQueryParams

  /**
   * `Type-safe` function for `redirect()` with `return_to` query parameter
   */
  const redirectWithReturnTo: RedirectWithReturnTo = useCallback(
    (name, return_to, options) =>
      redirect(name, currentState => {
        const extendedOptions = (() => {
          if (typeof options == 'function') return options(currentState)

          return options
        })()

        return {
          ...extendedOptions,
          queryParams: {
            return_to,
            ...extendedOptions?.queryParams
          }
        }
      }),
    []
  )

  /**
   * Redirect using current route's `queryParams` to another.
   *
   * Example: if current route `/a` has `?q=something`, redirecting to `/b` became `/b?q=something`.
   */
  const redirectWithQueryParams: RedirectWithQueryParams = useCallback(
    name => redirect(name, ({ queryParams }) => ({ queryParams })),
    []
  )

  const updateQueryParams = useCallback(
    (newQueryParams: Partial<typeof queryParams>) => {
      if (!name) return

      redirect(name, ({ queryParams, ...rest }) => ({
        ...rest,
        replace: true,
        queryParams: {
          ...queryParams,
          ...newQueryParams
        }
      }))
    },
    [name]
  )

  return {
    ...rest,
    on,
    onOneOf,
    redirect,
    redirectWithQueryParams,
    redirectWithReturnTo,
    name,
    queryParams,
    updateQueryParams
  }
}

export default useRouter
