import { FC, useMemo } from 'react'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { BaseText } from '@genie-fintech/ui/components/fields'
import { Password } from '@genie-fintech/ui/components/hook-fields'

import PasswordGuide from '$elements/PasswordGuide'

import { TypeSubmitForm } from '$hook-forms/types'
import { requiredString } from '$hook-forms/general-validators'
import useRequiredPasswordString from '$hook-forms/hooks/useRequiredPasswordString'

import { submit } from '$forms/common/style.css'

import { container } from './styles.css'

const baseSchema = z.object({
  password: requiredString
})

type TFields = z.infer<typeof baseSchema>

const defaultValues: TFields = {
  password: ''
}

export type ReplacePasswordFormFields = TFields

export type ReplacePasswordFormProps = TypeSubmitForm<TFields> & {
  emailValue?: string
}

export const ReplacePasswordForm: FC<ReplacePasswordFormProps> = ({
  onSuccess,
  disabled,
  emailValue
}) => {
  const { fieldSchema, getFailedRules } = useRequiredPasswordString()

  const dynamicSchema = useMemo(
    () => baseSchema.extend({ password: fieldSchema }),
    [fieldSchema]
  )
  const resolver = zodResolver(dynamicSchema)

  const { control, reset, handleSubmit, formState, getValues } = useForm({
    resolver,
    defaultValues,
    mode: 'all'
  })

  const isInvalid = disabled || !!Object.keys(formState.errors).length

  const passwordValue = getValues('password')
  const hasPassword = !!passwordValue
  const invalidRules = useMemo(
    () => getFailedRules(passwordValue),
    [passwordValue, getFailedRules]
  )

  return (
    <form
      className={container}
      onSubmit={handleSubmit((...handler) => onSuccess(reset, ...handler))}
      noValidate
      autoComplete="off"
    >
      {emailValue && (
        <BaseText
          label="Email"
          inputProps={{ value: emailValue }}
          disabled={true}
          required
        />
      )}

      <Password
        required
        control={control}
        name="password"
        label="New Password"
        disabled={disabled}
      />

      <button type="submit" className={submit} disabled={isInvalid}>
        Continue
      </button>

      <PasswordGuide invalidRules={invalidRules} disabled={!hasPassword} />
    </form>
  )
}

export default ReplacePasswordForm
