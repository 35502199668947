import { FC } from 'react'

import { TagsNoRef } from '@genie-fintech/ui/types'

import { getIcon } from './functions'
import { SIZE, Namespaces } from './constants'

export type BrandIconProps = {
  name: Namespaces
} & TagsNoRef<'svg'>

export const BrandIcon: FC<BrandIconProps> = ({ name, ...props }) => (
  <svg width={SIZE} height={SIZE} {...props}>
    <use xlinkHref={`#${getIcon(name)}`} />
  </svg>
)

export default BrandIcon
