import { FC, ReactNode, useId } from 'react'
import { Simplify } from 'type-fest'

import { propsWithClassNames, trueOrUndefined } from '../../../functions'

import { ToReferences } from '../../../types'
import {
  CommonElementControlProps,
  CommonFieldProps,
  CommonFieldStateProps
} from '../common/types'

import {
  field,
  requiredIndicator,
  label as labelStyle,
  message as messageStyle,
  description as descriptionStyle,
  fieldModes
} from '../common/style.css'

import { container } from './style.css'

type VNodes = {
  labelRef: HTMLLabelElement
  containerRef: HTMLDivElement
}
type References = Partial<ToReferences<VNodes>>

type ChildrenProps = {
  elementProps: CommonElementControlProps
  fieldProps: CommonFieldProps
}

type WithChildren = {
  children: (props: ChildrenProps) => ReactNode
}

export type FieldContainerProps = Simplify<
  CommonFieldProps & WithChildren & References
>

export const FieldContainer: FC<FieldContainerProps> = ({
  labelRef,
  containerRef,
  children,
  ...fieldProps
}) => {
  const {
    fullWidth,
    error,
    success,
    label,
    required,
    disabled,
    containerProps,
    message,
    description
  } = fieldProps

  const id = useId()

  const hasLabel = !!label

  const stateProps: CommonFieldStateProps = {
    'data-success': trueOrUndefined(success),
    'data-error': trueOrUndefined(error),
    'data-disabled': trueOrUndefined(disabled)
  }

  const containerFinalProps = propsWithClassNames(
    containerProps,
    field,
    fieldModes({ fullWidth }),
    container
  )

  const childrenProps: ChildrenProps = {
    elementProps: {
      id
    },
    fieldProps
  }

  return (
    <div ref={containerRef} {...stateProps} {...containerFinalProps}>
      {hasLabel && (
        <label ref={labelRef} htmlFor={id} className={labelStyle}>
          {label}
          {required && <span className={requiredIndicator}>*</span>}
        </label>
      )}

      {children(childrenProps)}

      {description && (
        <div className={descriptionStyle} children={description} />
      )}

      {message && <div className={messageStyle} children={message} />}
    </div>
  )
}

export default FieldContainer
