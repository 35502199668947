import { FC } from 'react'

import { propsWithClassNames } from '@genie-fintech/ui/functions'
import { TagsNoRef } from '@genie-fintech/ui/types'

import EnvironmentTag from '$elements/EnvironmentTag'
import BrandIcon, { BrandIconProps } from '$icons/BrandIcon'

import {
  container,
  name as nameStyle,
  description as descriptionStyle,
  info,
  link,
  figureCanvas,
  figureIcon,
  figure,
  figureInner,
  figureImage
} from './styles.css'

type AppCardProps = {
  href: string
  name: string
  description?: string
  environment?: string
  logo?: string
  brand?: BrandIconProps['name']
  aProps?: Omit<TagsNoRef<'a'>, 'href' | 'title'>
}

export const AppCard: FC<AppCardProps> = ({
  name,
  description,
  environment,
  logo,
  href,
  aProps,
  brand: brandName
}) => {
  const brand = (() => {
    if (logo || !brandName) return 'unknown' satisfies AppCardProps['brand']

    return brandName
  })()

  return (
    <div className={container}>
      <figure className={figure({ brand })}>
        <span className={figureInner}>
          {logo ? (
            <img src={logo} alt={name} className={figureImage} />
          ) : (
            <BrandIcon name={brand} className={figureIcon} />
          )}

          <span className={figureCanvas} />
        </span>
      </figure>

      <div className={info}>
        <h4 className={nameStyle}>{name}</h4>

        {environment && <EnvironmentTag env={environment} size="horizontal" />}

        {description && <p className={descriptionStyle}>{description}</p>}
      </div>

      <a
        href={href}
        title={[name, description].filter(Boolean).join('\n')}
        {...propsWithClassNames(aProps, link)}
      />
    </div>
  )
}

export default AppCard
