import { FC, PropsWithChildren } from 'react'
import { Root as RadixRoot, TabsProps } from '@radix-ui/react-tabs'

import { propsWithClassNames } from '../../functions'

import { FormatVariants, format } from './style.css'

export type RootProps = TabsProps & {
  format?: FormatVariants
}

export const Root: FC<PropsWithChildren<RootProps>> = ({
  format: formatVariants,
  ...defaultProps
}) => (
  <RadixRoot {...propsWithClassNames(defaultProps, format(formatVariants))} />
)

export default Root
