import usePageClass from './usePageClass'

export const useNoScrollY = (namespace: string, status: boolean) => {
  const prefix = 'no-scroll-y'

  const name = status ? namespace : undefined

  usePageClass({ prefix, name })
}

export default useNoScrollY
