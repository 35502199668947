export * as TRANSITIONS from './transitions'

/** Global UI ID */
export const ID = 'genie-ui' as const

/**
 * Mandatory/Favoured color types
 * to be used upon creating style variants, etc...
 */
export const KINDS = [
  'neutral',
  'primary',
  'success',
  'warning',
  'danger'
] as const
