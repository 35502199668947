import { FC, PropsWithChildren, ReactNode } from 'react'

import { TagsNoRef } from '@genie-fintech/ui/types'
import { propsWithClassNames } from '@genie-fintech/ui/functions'

import { container, content, summary } from './styles.css'

type DetailsProps = {
  title: ReactNode
} & TagsNoRef<'details'>

export const Details: FC<PropsWithChildren<DetailsProps>> = ({
  title,
  children,
  ...detailProps
}) => {
  if (!children) return null

  return (
    <details open {...propsWithClassNames(detailProps, container)}>
      <summary className={summary} onClick={e => e.preventDefault()}>
        {title}
      </summary>

      <p className={content} children={children} />
    </details>
  )
}

export default Details
