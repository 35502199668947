import { useMemo } from 'react'

import useSignal from '$actions/useSignal'

import { passwordRules } from '$store/settings'
import { generateRequiredPasswordString } from '$hook-forms/general-validators'

export const useRequiredPasswordString = () => {
  const rules = useSignal(passwordRules)
  const info = useMemo(() => generateRequiredPasswordString(rules), [rules])

  return info
}

export default useRequiredPasswordString
