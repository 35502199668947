import { useEffect } from 'react'

export type TypeusePageClass = {
  name?: string
  prefix?: string
}

export const usePageClass = ({
  name,
  prefix = 'ActivePage'
}: TypeusePageClass) => {
  const HTML = document.documentElement

  useEffect(() => {
    if (!name) return

    const PageClass = `${prefix}--${name}`

    HTML.classList.add(PageClass)

    return () => {
      HTML.classList.remove(PageClass)
    }
  }, [HTML.classList, prefix, name])
}

export default usePageClass
