import { AnyZodObject, z } from 'zod'

import { requiredString } from '$hook-forms/general-validators'

export const baseSchema = z.object({
  current_password: requiredString,
  password: requiredString,
  confirm_password: requiredString
})

export type TFields = z.infer<typeof baseSchema>

export const defaultValues: TFields = {
  current_password: '',
  password: '',
  confirm_password: ''
}

export const refineSchema = <Schema extends AnyZodObject>(schema: Schema) => {
  return schema
    .refine(({ current_password, password }) => password !== current_password, {
      message: 'Please use new password',
      path: ['password']
    })
    .refine(({ confirm_password, password }) => password === confirm_password, {
      message: "Password doesn't match",
      path: ['confirm_password']
    })
}
