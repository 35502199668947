import session from '$store/session'
import { removeTokens } from '$store/session/tokens'

import useSignal from './useSignal'
import useTokenLogout from './useTokenLogout'

export const useActiveProfileLogout = () => {
  const { currentTokenDetail } = useSignal(session)

  const { loading, cancel, data, error, runAsync } = useTokenLogout()

  return {
    logout: async () => {
      if (!currentTokenDetail?.token) return

      const result = runAsync(currentTokenDetail.token)

      result.then(() => {
        removeTokens([currentTokenDetail.id])
      })

      return result
    },
    loading,
    cancel,
    data,
    error
  }
}

export default useActiveProfileLogout
