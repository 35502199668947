import { FC } from 'react'

import usePageClass from '$browser/usePageClass'

import LoginBlock from '$blocks/LoginBlock'

const pageName = 'Login'

export const Login: FC = () => {
  usePageClass({ name: pageName })

  return <LoginBlock />
}

export default Login
