import { FC, useEffect } from 'react'

import usePageClass from '$browser/usePageClass'
import useSignal from '$actions/useSignal'
import useRouter from '$actions/useRouter'

import { profile } from '$store/profile'

import ReplacePasswordBlock from '$blocks/ReplacePasswordBlock'

const pageName = 'ReplacePassword'

export const ReplacePassword: FC = () => {
  usePageClass({ name: pageName })

  const { redirect } = useRouter()

  const info = useSignal(profile)
  const isValid = info?.data?.should_replace_password

  // Redirect back to profile if no valid condition present.
  useEffect(() => {
    if (isValid) return

    redirect('profileDetail')
  }, [redirect, isValid])

  if (!isValid) return 'No access.'

  return <ReplacePasswordBlock />
}

export default ReplacePassword
