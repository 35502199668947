import {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
  createContext
} from 'react'

import {
  routeState,
  summaryState,
  watchRouteState,
  watchSummaryState
} from '$app/router'

const useRouterValues = () => {
  const [routeStateValue, setRouteStateState] = useState(routeState)
  const [summaryStateValue, setSummaryStateState] = useState(summaryState)

  useEffect(() => watchRouteState(setRouteStateState), [])
  useEffect(() => watchSummaryState(setSummaryStateState), [])

  return {
    routeState: routeStateValue,
    summaryState: summaryStateValue
  }
}

const context = createContext({
  routeState,
  summaryState
})

const useRouterContext = () => useContext(context)

export const RouterProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useRouterValues()

  return <context.Provider value={value}>{children}</context.Provider>
}

export const useRoute = () => {
  const { routeState } = useRouterContext()

  return routeState
}

export const useRouteSummary = () => {
  const { summaryState } = useRouterContext()

  return summaryState
}
