import { FC, useEffect } from 'react'

import usePageClass from '$browser/usePageClass'
import useSignal from '$actions/useSignal'
import queryParams from '$store/queryParams'
import useRouter from '$actions/useRouter'

import { DEFAULT_ROUTE } from '$constants'

const pageName = 'Root'

export const Root: FC = () => {
  usePageClass({ name: pageName })

  const { params, cached } = useSignal(queryParams)
  const { redirect, redirectWithReturnTo } = useRouter()

  // - Redirect to `account selection` page when there's only `client_id` present.
  // - Redirect to `app authorization` page when there's both `client_id` & `auth_user` present.
  // Otherwise, redirect to profile
  useEffect(() => {
    // Wait till url query parameters are cached
    if (!cached) return

    if (params.client_id && params.auth_user) {
      redirect('authApp')
    } else if (params.client_id) {
      redirectWithReturnTo('authAccounts', 'authApp')

      return
    } else {
      redirect(DEFAULT_ROUTE)
    }
  }, [
    redirect,
    params.client_id,
    cached,
    params.auth_user,
    redirectWithReturnTo
  ])

  return null
}

export default Root
