import { signal } from '@preact/signals-core'

import { AuthMethods } from '$constants/auth'
import { LiteralUnion } from 'type-fest'

type Value = LiteralUnion<AuthMethods, string>

export const state = signal<Value | undefined>(undefined)

export const syncState = (method: Value) => {
  state.value = method
}
