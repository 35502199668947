import { batch } from '@preact/signals-core'
import { useMount } from 'ahooks'

import useRouter from '$actions/useRouter'

import { setCached, update } from '$store/queryParams'

export const useCacheQueryParamsOnce = () => {
  const { queryParams } = useRouter()

  useMount(() => {
    batch(() => {
      update(queryParams)
      setCached()
    })
  })
}

export default useCacheQueryParamsOnce
