import { FC, PropsWithChildren } from 'react'

import { Toast } from '@genie-fintech/ui/components'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { useIsMounted } from '@genie-fintech/ui/hooks'

import { state } from '$blocks/LoginBlock/tempState'

import CopyrightText from '$elements/CopyrightText'
import BgCover from '$elements/BgCover'

import AuthLogo from '$icons/AuthLogo'

import useLayoutClass from '$browser/useLayoutClass'
import useSignal from '$actions/useSignal'

import { authLayoutDescriptionText } from '$constants/texts'

import {
  aside,
  container,
  content,
  footer,
  inner,
  main,
  asideNav,
  asideContent,
  asideBackground,
  asideContentTitle,
  asideContentText,
  logo,
  asideBackgroundCover,
  copyrightText
} from './styles.css'

export const AuthLayout: FC<PropsWithChildren> = ({ children }) => {
  useLayoutClass({ name: 'AuthLayout' })

  const method = useSignal(state)

  const ready = useIsMounted()

  return (
    <section className={container} data-ready={trueOrUndefined(ready)}>
      <div className={inner}>
        <aside className={aside}>
          <nav className={asideNav}>
            <AuthLogo className={logo} />
          </nav>

          <article className={asideContent}>
            <h3 className={asideContentTitle}>Welcome back !</h3>

            <div className={asideContentText}>
              <p>{authLayoutDescriptionText}</p>
            </div>
          </article>

          <div className={asideBackground} hidden>
            <BgCover className={asideBackgroundCover} />
          </div>
        </aside>

        <main className={main}>
          <div />

          <article className={content}>{children}</article>

          <footer className={footer}>
            {method === 'email' && (
              <Toast
                title="EMAIL MFA AUTHENTICATION!"
                kind="warning"
                fullWidth
                type="faint"
                headerProps={{
                  style: {
                    maxWidth: 440,
                    marginLeft: 'auto',
                    marginRight: 'auto'
                  }
                }}
              >
                Verify your email to enable Multi-Factor Authentication for
                added security latest by <strong>November 26, 2024</strong>.
              </Toast>
            )}

            <CopyrightText className={copyrightText} />
          </footer>
        </main>
      </div>
    </section>
  )
}

export default AuthLayout
